import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { returnSupplierName } from '../../../lib/helpers/crossings';

import BasketItemCrossingItinerary from './BasketItemCrossingItinerary';

import { BasketItemRow, BasketItemCol } from '../BasketItem.style';

function BasketItemFerry({
  inboundItinerary,
  outboundItinerary,
  isItx,
  ...props
}) {
  const supplierName = returnSupplierName(props.suppliers, props.supplierCode);

  return (
    <BasketItemRow>
      <BasketItemCol bigBasket={props.bigBasket}>
        <BasketItemCrossingItinerary
          bigBasket={props.bigBasket}
          isItx={isItx}
          ports={props.ports}
          supplierName={supplierName}
          type="Outbound"
          {...outboundItinerary}
        />
      </BasketItemCol>

      {inboundItinerary &&
        <BasketItemCol bigBasket={props.bigBasket}>
          <BasketItemCrossingItinerary
            bigBasket={props.bigBasket}
            isItx={isItx}
            ports={props.ports}
            supplierName={supplierName}
            type="Return"
            {...inboundItinerary}
          />
        </BasketItemCol>
      }
    </BasketItemRow>
  );
}

BasketItemFerry.propTypes = {
  isItx: PropTypes.bool.isRequired,
  bigBasket: PropTypes.bool.isRequired,
  outboundItinerary: PropTypes.shape({}).isRequired,
  inboundItinerary: PropTypes.shape({}),
  suppliers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supplierCode: PropTypes.string.isRequired,
};

BasketItemFerry.defaultProps = {
  inboundItinerary: null,
};

export default memo(BasketItemFerry);
