import styled from 'styled-components';

import { above } from '../../styles/libs';
import { ButtonBordered } from '../ui/Button';
import Text from '../ui/Text';

export const Wrapper = styled.div`
  display: flex;
  align-items: left;
  padding: 1rem 1.75rem;
  background: ${({ theme }) => theme.COLOR_WHITE};
  ${({ theme }) => above(theme.TABLET)`
    margin-top: auto;
    padding: 1.25rem 1.75rem;
    bottom: 0;
  `}
`;

export const Message = styled(Text)`
  flex-grow: 1;
  font-size: 0.875rem;
`;

export const ViewButton = styled(ButtonBordered)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  margin-left: 1rem;
`;
