import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import formatPrice from '../../lib/formatPrice';
import ErrorTypesMessage from '../ErrorTypeMessage';
import QuoteRemoveComponent from '../Quote/QuoteRemoveComponent';
import { Text } from '../ui';
import BasketExtraStyled, { Header, Price } from './BasketExtra.style';
import { RemoveButton, WhiteButton } from './BasketItem.style';
import ConditionalWrapper from '../ConditionalWrapper';
import testingAttr from '../../lib/testingAttr';
import { allowRemoveExtra } from '../../lib/helpers/amend';
import EXTRA_TYPES from '../../config/extraTypes';
import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

class BasketExtra extends Component {
  static propTypes = {
    amendLoading: PropTypes.bool,
    configuration: PropTypes.shape(IbePropTypes.configuration),
    isAmend: PropTypes.bool,
    isItx: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isPreviousBookingBasket: PropTypes.bool,
    isBookingBasket: PropTypes.bool,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
    }),
    type: PropTypes.string,
    onAddOSNV: PropTypes.func,
    shouldResetSession: PropTypes.bool,
    resetSession: PropTypes.func,
    openRemovePackagePopUp: PropTypes.func,
    openMembershipRemovePopUp: PropTypes.func,
    wouldInvalidateItx: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    amendLoading: false,
    configuration: {},
    isAmend: false,
    onAddOSNV: () => {},
    shouldResetSession: false,
    resetSession: () => {},
    theme: {
      COLOR_WHITE: '',
    },
    type: '',
    isPreviousBookingBasket: false,
    isBookingBasket: true,
    openRemovePackagePopUp: () => {},
    openMembershipRemovePopUp: () => {},
  }

  state = {
    error: false,
  }

  handleSubmit = (state) => {
    if (this.props.shouldResetSession) {
      this.props.resetSession();
    }
    this.setState(state);
  }

  handleError = (error) => {
    this.setState({ error });
  }

  render() {
    const {
      amendLoading,
      configuration,
      id,
      isAmend,
      name,
      quantity,
      totalPrice,
      isItx,
      wouldInvalidateItx,
      onAddOSNV,
      openRemovePackagePopUp,
      openMembershipRemovePopUp,
      isPreviousBookingBasket,
      isBookingBasket,
      type,
    } = this.props;
    const { error } = this.state;
    let useQuoteRemoveComponent = !isItx || !wouldInvalidateItx;
    let onRemove = useQuoteRemoveComponent ? undefined : () => openRemovePackagePopUp();
    const isMembership = type === EXTRA_TYPES.MEMBERSHIP;
    const isOsnv = type === EXTRA_TYPES.SITE_NIGHT_VOUCHER;

    if (isBookingBasket) { // @TODO define un cancellable logic
      useQuoteRemoveComponent = false;
      onRemove = () => openRemovePackagePopUp();
    }
    if (isMembership && openMembershipRemovePopUp) {
      useQuoteRemoveComponent = false;
      onRemove = () => openMembershipRemovePopUp();
    }

    const showBin = allowRemoveExtra(isAmend || isBookingBasket, configuration, this.props);

    return (
      <BasketExtraStyled>
        <Header>
          <Text {...testingAttr('basket-extra-text')}>
            <b>{name}{isBookingBasket ? '' : ` x ${quantity}`}</b>
          </Text>
          {!isItx &&
            <Price><b>{formatPrice(totalPrice)}</b></Price>
          }
        </Header>
        {type === EXTRA_TYPES.SITE_NIGHT_VOUCHER && isBookingBasket &&
        <Header>
          <Text>{quantity} x Vouchers</Text>
          <Text>{formatPrice(totalPrice / quantity)} per voucher</Text>
        </Header>}
        <Header justifyContent="flex-start">
          {isOsnv && (isAmend || isBookingBasket) &&
          <WhiteButton
            size="small"
            onClick={onAddOSNV}
            dictionary={dictionaryItem('Amend', 'SiteNightVoucher', 'Add')}
            fullWidth={false}
            isLoading={amendLoading}
          />}
          {!isPreviousBookingBasket && !isMembership && showBin &&
            <ConditionalWrapper
              condition={useQuoteRemoveComponent}
              wrapper={children => (
                <QuoteRemoveComponent
                  componentIds={id}
                  onError={this.handleError}
                  onSubmit={this.handleSubmit}
                >
                  {children}
                </QuoteRemoveComponent>
              )}
            >
              <RemoveButton onClick={onRemove} />
            </ConditionalWrapper>
          }
        </Header>
        {error &&
          <ErrorTypesMessage error={error} marginTop />
        }
      </BasketExtraStyled>
    );
  }
}

export default withTheme(BasketExtra);
