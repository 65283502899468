import { USER_DECLINED_UP_SELL_IN_SESSION } from '../../constants';

class ItxProvider {
  constructor(
    quoteHelperObj,
    payloadHelperObj,
    qtyOvernightStaysRequired,
    qtyOverseasSiteNightVouchersRequired,
  ) {
    this.quoteHelperObj = quoteHelperObj;
    this.payloadHelperObj = payloadHelperObj;

    this.qtyOvernightStaysRequired = qtyOvernightStaysRequired;
    this.qtyOverseasSiteNightVouchersRequired = qtyOverseasSiteNightVouchersRequired;

    this.isItx = false;

    this.validate();
  }

  getAmountOfNights = () => {
    const nights =
      (this.quoteHelperObj.amountOfNights || 0) + (this.payloadHelperObj.amountOfNights || 0);

    return nights;
  }

  getAmountOfSiteNightVouchers = () => {
    const snvs =
      (this.quoteHelperObj.amountOfSiteNightVouchers || 0) +
      (this.payloadHelperObj.amountOfSiteNightVouchers || 0);

    return snvs;
  }

  // If amount of night in the user's booking is bigger then amount of nights required
  // AND booked crossing is return crossing
  // OR
  // If amount of site night voucher in the user's booking
  // is bigger then amount of site night vouchers required
  // AND booked crossing is return crossing
  // THEN user will receive ITX offer.
  isITXApplicable = (
    returnCrossing,
    sumOfNights,
    sumOfSiteNightVouchers,
    nightsReq,
    siteNightVouchersReq,
  ) => {
    const isItx =
      ((
        (sumOfNights >= nightsReq) ||
        (sumOfSiteNightVouchers >= siteNightVouchersReq)
      ) &&
        returnCrossing);

    return isItx;
  }

  validate = () => {
    const nightsCount = this.getAmountOfNights();
    const siteNightVouchersAmount = this.getAmountOfSiteNightVouchers();
    const isInbound =
      this.quoteHelperObj.hasInboundCrossing || this.payloadHelperObj.hasInboundCrossing;

    // validate wether payload + quote creates Itx package.
    this.isItx = this.isITXApplicable(
      isInbound,
      nightsCount,
      siteNightVouchersAmount,
      this.qtyOvernightStaysRequired,
      this.qtyOverseasSiteNightVouchersRequired,
    );
  }

  static isITXCrossingAvailable = (crossing, allCrossings = []) => {
    if (!crossing) return false;
    if (crossing.fareType === 1) return true;
    if (!allCrossings.length) return false;

    const itx = allCrossings
      .filter(({ fareType }) => fareType === 1)
      .find(
        c => c.relatedFareTypeId === crossing.relatedFareTypeId
        && c.charges.totalAmount < crossing.charges.totalAmount,
      );

    return !!itx;
  }

  static getItxTypeId = fareTypes => ItxProvider.getCrossingTypeId(fareTypes, 'ITX');

  static getRetailTypeId = fareTypes => ItxProvider.getCrossingTypeId(fareTypes, 'Retail');

  static getCrossingTypeId = (fareTypes, value) => {
    if (fareTypes && fareTypes.length) {
      const crossingDatum = fareTypes.find(t => t.value === value);
      if (crossingDatum) {
        return crossingDatum.key;
      }
    }

    return -1;
  }

  static cheaperItxCrossingsExist = ({ crossingBookings = [] }) => crossingBookings
    .some(({ changeType }) => changeType === 1);

  static getItxCrossing = (relatedFareTypeId, crossings, fareTypes) => {
    const itxId = ItxProvider.getItxTypeId(fareTypes);
    return crossings.find(c => (
      c.relatedFareTypeId === relatedFareTypeId
      && c.fareType === itxId));
  }

  static checkToShowPackageUpgrade = (
    quote,
    numnberOfNights,
  ) => {
    const cheaperItxCrossingAvailable = ItxProvider.cheaperItxCrossingsExist(quote);
    const aboveMinNights = numnberOfNights >= 4;

    return cheaperItxCrossingAvailable && aboveMinNights;
  }

  static checkToShowPackageUpgradeDialog = (
    quote,
    numberOfNights,
  ) => {
    const showPackageUpgrade = ItxProvider.checkToShowPackageUpgrade(
      quote,
      numberOfNights,
    );
    const userHasNotDeclinedUpgrade = !window.sessionStorage.getItem(
      USER_DECLINED_UP_SELL_IN_SESSION,
    );

    return showPackageUpgrade && userHasNotDeclinedUpgrade;
  }

  static getItxSaving = quote => quote.crossingBookings
    .reduce((total, amount) => total + amount.changePrice, 0).toFixed(2);

  static checkToShowUpsell = (quote) => {
    const cheaperItxCrossingAvailable = ItxProvider.cheaperItxCrossingsExist(quote);
    const maxNights = ItxProvider.getMaxNightsFromQuote(quote);
    const belowMinNights = maxNights < 4;

    return cheaperItxCrossingAvailable && belowMinNights;
  }

  static getMaxNightsFromQuote = (quote) => {
    let maxNights = 0;

    if (quote) {
      if (quote.campsiteBookings && quote.campsiteBookings.length) {
        quote.campsiteBookings.forEach((campsiteBooking) => {
          campsiteBooking.pitches.forEach((pitch) => {
            maxNights = Math.max(pitch.numNights, maxNights);
          });
        });
      }

      if (quote.extras && quote.extras.length) {
        quote.extras.forEach((extra) => {
          if (extra.name === 'Overseas Site Night Vouchers') {
            maxNights = Math.max(extra.quantity, maxNights);
          }
        });
      }
    }

    return maxNights;
  }
}

export default ItxProvider;
