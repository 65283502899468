import React from 'react';
import PropTypes from 'prop-types';

const BasketImportantInformation = ({ importantInformation }) => {
  if (!importantInformation?.length) {
    return null;
  }
  return (
    <ul>
      {importantInformation.map((information, id) => (
        <li key={information}>{information}</li>
      ))}
    </ul>
  );
};

BasketImportantInformation.propTypes = {
  importantInformation: PropTypes.arrayOf(PropTypes.string),
};

BasketImportantInformation.defaultProps = {
  importantInformation: [],
};

export default BasketImportantInformation;
