import PropTypes from 'prop-types';
import React from 'react';
import { Container } from './Drawer.style';

const defaultProps = {
  backgroundColor: null,
  className: '',
  handleDrawerToggle() { },
};

const propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleDrawerToggle: PropTypes.func,
};

function DrawerContent(props) {
  const mapProps = {
    handleDrawerToggle: props.handleDrawerToggle,
  };

  return (
    <Container
      backgroundColor={props.backgroundColor}
      className={props.className}
    >
      {React.Children.map(props.children, child => (
        React.cloneElement(child, mapProps)
      ))}
    </Container>
  );
}

DrawerContent.defaultProps = defaultProps;
DrawerContent.propTypes = propTypes;

export default DrawerContent;
