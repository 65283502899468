import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';

import MessageWarningStyle from '../ui/Message/MessageWarning.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import IbePropTypes from '../../IbePropTypes';
import { getDictionaryItem } from '../../hocs/withDictionary';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  data: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
    error: PropTypes.shape({}),
    loading: PropTypes.bool.isRequired,
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
    networkError: PropTypes.shape(IbePropTypes.networkError),
  }),
  errorCode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
};

const defaultProps = {
  data: {
    configuration: { errorCodeTypes: [] },
    error: null,
    loading: false,
  },
  errorCode: null,
  error: {},
  children: null,
  loading: false,
};

function ErrorTypeMessage({
  client,
  children,
  loading,
  errorCode,
  error,
  data,
  ...props
}) {
  if (data.loading) return null;

  const { errorCodeTypes } = data.configuration;

  if (!errorCodeTypes) return <ErrorTypeMessage error={new Error('No configuration available.')} />;
  let code = errorCode;

  const arrayOfErrors = [];

  // BED is returning either process error or validation errors.
  // Process errors are represented by error code.
  // Validation errors are represented by array of errors.
  if (error.networkError && error.networkError.result) {
    // this handles BED process errors
    if (error.networkError.result.errorCode) {
      code = error.networkError.result.errorCode;
      // else handles BED validation errors
    } else {
      Object.keys(error.networkError.result).forEach((key) => {
        error.networkError.result[key].forEach((errorInArray) => {
          arrayOfErrors.push(errorInArray);
        });
      });
    }
  }

  const errorType = errorCodeTypes.find(({ key }) => key === code);
  const errorMessage = errorType ? errorType.value : errorCode;

  const dictItem = getDictionaryItem(client, errorMessage);

  if (dictItem || errorType) {
    return (
      <MessageWarningStyle {...props}>
        {dictItem || errorMessage || error.message || 'There was an error.'}
      </MessageWarningStyle>
    );
  }

  let index = 0;

  if (arrayOfErrors.length) {
    return (
      <Fragment>
        {arrayOfErrors.map((errorInArray) => {
          index += 1;
          return (
            <MessageWarningStyle key={`${errorInArray}-${index + 1}`} {...props}>
              {errorInArray}
            </MessageWarningStyle>
          );
        })}
      </Fragment>
    );
  }

  return (
    <MessageWarningStyle {...props}>
      {children || 'There was an error.'}
    </MessageWarningStyle>
  );
}

ErrorTypeMessage.propTypes = propTypes;
ErrorTypeMessage.defaultProps = defaultProps;

export default compose(
  graphql(GET_CONFIGURATION),
  withApollo,
)(ErrorTypeMessage);
