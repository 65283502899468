import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { v4 as uuid } from 'uuid';

import ButtonLink from '../ui/Button/ButtonLink';
import { Wrapper, ViewButton, Message } from './BasketPassivePrompt.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import GET_DICTIONARY_ITEM from '../../hocs/withDictionary/getDictionaryItem.gql';

const propTypes = {
  onViewPortClick: PropTypes.func.isRequired,
  onViewOnsvClick: PropTypes.func.isRequired,
};

const SITE_NIGHT_MATCH = 'BasketUpSellSiteNightLink'; // matched in the dictionary
const OSNV_MATCH = 'BasketUpSellOnsvLink'; // matched in the dictionary

const parseMessageParts = (part, onViewOnsvClick, onViewPortClick) => {
  switch (part) {
    case SITE_NIGHT_MATCH:
      return <ButtonLink key={uuid()} dictionary={dictionaryItem('BasketUpSell__SiteNightLink')} span onClick={onViewPortClick} />;
    case OSNV_MATCH:
      return <ButtonLink key={uuid()} dictionary={dictionaryItem('BasketUpSell__OnsvLink')} span onClick={onViewOnsvClick} />;
    default:
      return part;
  }
};

const BasketUpSell = props => (
  <Wrapper>
    <Query query={GET_DICTIONARY_ITEM} variables={{ key: 'BasketUpSell__Message' }}>
      {({ data }) => {
        if (!data || !data.dictionaryItem) return null;
        const message = data.dictionaryItem && data.dictionaryItem.value;
        const parts = message.split(/{{(.*?)}}/);

        return (
          <Message>
            {parts.map(part => (
              parseMessageParts(part, props.onViewOnsvClick, props.onViewPortClick)))
            }
          </Message>
        );
      }}
    </Query>
    <ViewButton
      dictionary={dictionaryItem('BasketUpSell', 'View')}
      onClick={props.onViewPortClick}
      size="small"
    />
  </Wrapper>
);

BasketUpSell.propTypes = propTypes;

export default BasketUpSell;
