import styled, { css } from 'styled-components';

function isVetical(position) {
  return ['top', 'bottom'].includes(position);
}

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};

  ${({ position }) => {
    if (!isVetical(position)) return '';
    return css`
      flex-direction: column;
    `;
  }}
`;
