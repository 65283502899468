import React from 'react';

import { Wrapper, Message } from './BasketPassivePrompt.style';
import { dictionaryItem } from '../../hocs/withDictionary';

const BasketOverlappingSiteDatesPassivePrompt = props => (
  <Wrapper>
    <Message dictionary={dictionaryItem('BasketOverlappingSiteDates__Message')} />
  </Wrapper>
);

export default BasketOverlappingSiteDatesPassivePrompt;
