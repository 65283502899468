/**
 * these values match strings passed as values in the configuration,
 */

export const types = {
  OFF: 'Off',
  WARNING: 'Warning',
  PREVENT: 'Prevent',
};

export default types;
