export const getPitchBookings = (campsiteBookings) => {
  if (!campsiteBookings.length) return [];

  const pitchBookings = [];
  campsiteBookings.forEach((booking) => {
    booking.pitches.forEach((pitch) => {
      const newBooking = { ...booking };

      newBooking.pitch = pitch;
      newBooking.bookingDates = newBooking.pitch.bookingDates;

      pitchBookings.push(newBooking);
    });
  });
  return pitchBookings;
};

export const getSplittedItineraries = (crossingBookings) => {
  const bookingsPerItinerary = [];
  crossingBookings.forEach((booking) => {
    const outNewBooking = {};
    outNewBooking.supplierCode = booking.supplierCode;
    outNewBooking.itinerary = booking.outboundItinerary;
    outNewBooking.bookingDates = booking.outboundItinerary.timeTable;

    bookingsPerItinerary.push(outNewBooking);

    if (booking.inboundItinerary) {
      const inNewBooking = {};
      inNewBooking.supplierCode = booking.supplierCode;
      inNewBooking.itinerary = booking.inboundItinerary;
      inNewBooking.bookingDates = booking.inboundItinerary.timeTable;
      bookingsPerItinerary.push(inNewBooking);
    }
  });
  return bookingsPerItinerary;
};
