import { Children, PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { stringify } from 'query-string';
import { isArray } from 'lodash';

import { updateQuoteCache } from '../../resolvers/quote';

import withStateMutation from '../../hocs/withStateMutation';

import DELETE_QUOTE_COMPONENT from './graphql/deleteQuoteComponent';

import { USER_DECLINED_UP_SELL_IN_SESSION } from '../../lib/constants';

export function pathBuilder({ args }) {
  return `Reservations/DeleteQuoteComponents?${stringify(args)}`;
}

class QuoteRemoveComponent extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    componentIds: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
    deleteQuoteComponent: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
  }

  static defaultProps = {
    loading: false,
    onSubmit() { },
    onError() { },
    onSuccess() { },
  }

  handleClick = async () => {
    const {
      deleteQuoteComponent,
      componentIds,
      onError,
      onSubmit,
    } = this.props;

    onSubmit({ error: false });

    const ids = isArray(componentIds) ? componentIds : [componentIds];

    await deleteQuoteComponent(ids)
      .then((response) => {
        const {
          data: {
            quoteResponse: {
              quote,
            },
          },
        } = response;

        const campsiteBookings = !!quote.campsiteBookings.length;
        const crossingBookings = !!quote.crossingBookings.length;
        const extras = !!quote.extras.length;

        if (!campsiteBookings && !crossingBookings && !extras) {
          window.sessionStorage.removeItem(USER_DECLINED_UP_SELL_IN_SESSION);
        }

        this.props.onSuccess(response);
      })
      .catch(error => onError(error));
  }

  render() {
    return Children.map(this.props.children, child => (
      child && cloneElement(child, {
        disabled: this.props.loading,
        onClick: this.handleClick,
      })
    ));
  }
}

export default compose(
  graphql(DELETE_QUOTE_COMPONENT, {
    options: {
      notifyOnNetworkStatusChange: true,
    },
    props: ({ mutate }) => ({
      deleteQuoteComponent: ids => mutate({
        update: updateQuoteCache,
        variables: {
          ids,
          pathBuilder,
        },
      }),
    }),
  }),
  withStateMutation({ name: 'deleteQuoteComponent' }),
)(QuoteRemoveComponent);
