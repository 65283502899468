import { Children, PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { stringify } from 'query-string';
import { isArray } from 'lodash';
import { updateQuoteCache } from '../../resolvers/quote';

import withStateMutation from '../../hocs/withStateMutation';

import ACCEPT_QUOTE_COMPONENT from './graphql/acceptQuoteComponent';

export function pathBuilder({ args }) {
  return `Reservations/AcceptQuoteComponents?${stringify(args)}`;
}

class QuoteAcceptComponent extends PureComponent {
  static propTypes = {
    acceptQuoteComponent: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    componentIds: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    loading: false,
    onSubmit() { },
    onError() { },
    onSuccess() { },
  }

  handleClick = async () => {
    const {
      acceptQuoteComponent,
      componentIds,
      onError,
      onSubmit,
    } = this.props;

    onSubmit({ error: false });

    const ids = isArray(componentIds) ? componentIds : [componentIds];

    // if ids exist then accept all, other wise just ignore this and
    // try accessing checkout
    if (ids.length) {
      await acceptQuoteComponent(ids)
        .then(response => this.props.onSuccess(response))
        .catch(error => onError(error));
      return;
    }

    this.props.onSuccess();
  }

  render() {
    return Children.map(this.props.children, child => (
      child && cloneElement(child, {
        disabled: this.props.loading || this.props.disabled,
        onClick: this.handleClick,
      })
    ));
  }
}

export default compose(
  graphql(ACCEPT_QUOTE_COMPONENT, {
    options: {
      notifyOnNetworkStatusChange: true,
    },
    props: ({ mutate }) => ({
      acceptQuoteComponent: ids => mutate({
        update: updateQuoteCache,
        variables: {
          input: {
            ids,
          },
        },
      }),
    }),
  }),
  withStateMutation({ name: 'acceptQuoteComponent' }),
)(QuoteAcceptComponent);
