import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { returnPortName } from '../../../lib/helpers/crossings';
import formatDate from '../../../lib/format';

import BookingSummaryItem from '../../BookingSummary/BookingSummaryItem';
import Title from '../../ui/Title';

import { Header } from '../BasketItem.style';

import { DATE_FORMAT_DISPLAY, TIME_FORMAT_MILITARY } from '../../../config/locale';

function formatDatePort(date, port) {
  return `${formatDate(date, DATE_FORMAT_DISPLAY)}, ${formatDate(date, TIME_FORMAT_MILITARY)}, ${port}`;
}

function BasketItemCrossingItinerary({
  bigBasket,
  isItx,
  ports,
  supplierName,
  type,
  ...itinerary
}) {
  return (
    <Fragment>
      <Header full={bigBasket}>
        <Title tag={3} size={4}>{supplierName}: {type}</Title>
      </Header>

      <BookingSummaryItem
        flex="0 0 4rem"
        basket
        label="Depart"
        value={{
          name: formatDatePort(
            itinerary.timeTable.fromDate,
            returnPortName(ports, itinerary.departurePort),
          ),
          price: null,
        }}
      />

      <BookingSummaryItem
        flex="0 0 4rem"
        basket
        label="Arrive"
        value={{
          name: formatDatePort(
            itinerary.timeTable.toDate,
            returnPortName(ports, itinerary.arrivalPort),
          ),
          price: null,
        }}
      />

      {bigBasket &&
        <Fragment>
          {itinerary.transportName &&
            <BookingSummaryItem
              flex="0 0 4rem"
              basket
              label="Ship"
              value={{ name: itinerary.transportName, price: null }}
            />
          }

          <BookingSummaryItem
            flex="0 0 4rem"
            basket
            label="Cabin"
            value={
              itinerary.accommodation && itinerary.accommodation.length
                ? itinerary.accommodation.map(a => ({ name: `${a.name} x ${a.quantity}` }))
                : { name: 'None', price: null }
            }
          />

          <BookingSummaryItem
            flex="0 0 4rem"
            basket
            label="Extras"
            value={itinerary.supplements.map(supplement => ({
              name: supplement.quantity ? `${supplement.description} x ${supplement.quantity}` : 'None',
              price: isItx ? null : supplement.totalPrice,
              quantity: supplement.quantity,
            }))}
          />
        </Fragment>
      }
    </Fragment>
  );
}

BasketItemCrossingItinerary.propTypes = {
  supplierName: PropTypes.string.isRequired,
  bigBasket: PropTypes.bool.isRequired,
  isItx: PropTypes.bool,
  ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
};

BasketItemCrossingItinerary.defaultProps = {
  isItx: false,
};

export default memo(BasketItemCrossingItinerary);
