import EventEmitter from 'eventemitter3';
import theme from '../styles/config/theme';

class ViewManager extends EventEmitter {
  EVENT_WILL_UPDATE = 'eventWillUpdate';

  EVENT_UPDATE = 'eventUpdate';

  EVENT_DID_UPDATE = 'eventDidUpdate';

  EVENT_TICK = 'eventTick';

  EVENT_RESIZE = 'eventResize';

  EVENT_SCROLL = 'eventScroll';

  breakpoint = null;

  previousBreakpoint = null;

  rafID = null;

  lastUpdateTime = null;

  scrollHeight = null;

  scrollTop = null;

  viewHeight = null;

  viewWidth = null;

  active = false;

  static get instance() {
    /* eslint-disable no-underscore-dangle */
    if (typeof window !== 'undefined') {
      if (!window.__camcViewManager) {
        window.__camcViewManager = new ViewManager('singleton');
      }

      return window.__camcViewManager;
    }

    return null;
    /* eslint-enable no-underscore-dangle */
  }

  constructor(string) {
    super();

    this.init();

    if (string !== 'singleton') throw new Error('Cannot construct singleton');
  }

  /**
   * Public
   */

  invalidate() {
    this.scrollInvalid = true;
    this.resizeInvalid = true;
  }

  getViewWidth() { return this.viewWidth; }

  getViewHeight() { return this.viewHeight; }

  getScrollTop() { return this.scrollTop; }

  getScrollHeight() { return this.scrollHeight; }

  getBreakpoint() { return this.breakpoint.value; }

  /**
   * Private
   */

  init() {
    this.bind();

    this.scrollHeight = document.body.scrollHeight; //  forces layout / reflow
    this.viewHeight = window.innerHeight; //  forces layout / reflow
    this.viewWidth = window.innerWidth; //  forces layout / reflow
    this.scrollTop = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop
      || 0; //  forces layout / reflow
    this.breakpoint = ViewManager.calculateBreakpoint(this.viewWidth);
    this.previousBreakpoint = this.breakpoint;
  }

  bind() {
    this.onScroll = this.onScroll.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  activate() {
    if (this.active === false) {
      this.active = true;

      window.addEventListener('scroll', this.onScroll, { passive: true, capture: true }); // capture to ensure scroll event from scrolling in all containers.
      window.addEventListener('resize', this.onResize);

      if (this.rafID === null) {
        this.lastUpdateTime = new Date().getTime();
        this.rafID = window.requestAnimationFrame(this.onUpdate);
      }
    }
  }

  deactivate() {
    this.active = false;

    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);

    window.cancelAnimationFrame(this.rafID);

    this.rafID = null;
    this.lastUpdateTime = null;
  }

  hasTransitionedBelow(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.previousBreakpoint.value >= value && this.breakpoint.value < value;
  }

  hasTransitionedBelowAndWithin(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.previousBreakpoint.value > value && this.breakpoint.value <= value;
  }

  hasTransitionedAbove(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.previousBreakpoint.value <= value && this.breakpoint.value > value;
  }

  hasTransitionedAboveAndWithin(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.previousBreakpoint.value < value && this.breakpoint.value >= value;
  }

  isBelow(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.breakpoint.value < value;
  }

  isBelowAndWithin(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.breakpoint.value <= value;
  }

  isAbove(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.breakpoint.value > value;
  }

  isAboveAndWithin(breakpoint) {
    const value = typeof breakpoint === 'number' ? breakpoint : breakpoint.value;
    return this.breakpoint.value >= value;
  }

  onUpdate() {
    const now = new Date().getTime();
    let delta = 0;
    if (this.lastUpdateTime !== null) {
      delta = now - this.lastUpdateTime;
    }
    this.lastUpdateTime = now;

    const scrollElement = document.scrollingElement || document.documentElement;

    const scrollTop = scrollElement.scrollTop || 0;
    if (scrollTop !== this.scrollTop) {
      this.scrollInvalid = true;
      this.scrollTop = scrollTop;
    }

    let data = {
      delta,
      breakpointUpdate: false,
      previousBreakpoint: { ...this.previousBreakpoint },
      currentBreakpoint: { ...this.breakpoint },
      scrollTop,
      scrollUpdate: false,
      resizeUpdate: false,
      scrollHeight: this.scrollHeight,
      viewHeight: this.viewHeight,
      viewWidth: this.viewWidth,
    };

    if (this.scrollInvalid || this.resizeInvalid) {
      if (this.resizeInvalid) {
        this.scrollHeight = document.body.scrollHeight; //  forces layout / reflow
        this.viewHeight = window.innerHeight; //  forces layout / reflow
        this.viewWidth = window.innerWidth; //  forces layout / reflow

        const breakpoint = ViewManager.calculateBreakpoint(this.viewWidth);
        if (breakpoint.value !== this.breakpoint.value) {
          data.breakpointUpdate = true;
          data.previousBreakpoint = { ...this.breakpoint };
          data.currentBreakpoint = { ...breakpoint };
        }
        this.previousBreakpoint = this.breakpoint;
        this.breakpoint = breakpoint;
      }

      data = {
        ...data,
        scrollUpdate: this.scrollInvalid,
        resizeUpdate: this.resizeInvalid,
        scrollHeight: this.scrollHeight,
        viewHeight: this.viewHeight,
        viewWidth: this.viewWidth,
      };

      this.scrollInvalid = false;
      this.resizeInvalid = false;

      this.emit(this.EVENT_WILL_UPDATE, data);
      this.emit(this.EVENT_UPDATE, data);
      this.emit(this.EVENT_DID_UPDATE, data);
    }

    this.emit(this.EVENT_TICK, data);

    this.rafID = window.requestAnimationFrame(this.onUpdate);
  }

  onScroll() {
    this.scrollInvalid = true;

    this.emit(this.EVENT_SCROLL);
  }

  onResize() {
    this.resizeInvalid = true;

    this.emit(this.EVENT_RESIZE);
  }

  static calculateBreakpoint(viewWidth) {
    return theme.BREAKPOINTS.reduce((breakpointPrev, breakpointNext) => (
      (viewWidth >= breakpointPrev.value && viewWidth < breakpointNext.value)
        ? breakpointPrev
        : breakpointNext
    ));
  }
}

export default ViewManager.instance;
