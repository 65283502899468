import { getPitchBookings } from './Checkout/flattenBookingArray';

// SumOfNight - how many night have user's booking
// SumOfSnv - how many site night voucher user booked,
// nightsReq - how many nights is required to obtain ITX
// snvReq - how many site night vouchers is required to obtain ITX
export default function IsITXApplicable(returnCrossing, sumOfNights, sumOfSnv, nightsReq, snvReq) {
  // If amount of night in the user's booking is bigger then amount of nights required
  // AND booked crossing is return crossing
  // OR
  // If amount of site night voucher in the user's booking
  // is bigger then amount of site night vouchers required
  // AND booked crossing is return crossing
  // THEN user will receive ITX offer.
  return (((sumOfNights >= nightsReq) || (sumOfSnv >= snvReq)) && returnCrossing);
}

export const sumAllNights = (campsiteBookings) => {
  if (!campsiteBookings) return 0;
  if (!campsiteBookings.length) return 0;
  // get array of array which index 0 contains object with ...props and pitch.
  // It contains as well pitches but it should be ignored!.
  const pitchBookings = getPitchBookings(campsiteBookings);

  // create array of pitch then of numNights with map and then sum them with reduce.
  // TODO: ADD FILTERING FOR OVERSEAS CAMPSITES WHEN BACKEND WILL FIX THAT!
  const sumOfNights = pitchBookings
    .map(b => b.pitch)
    .map(p => p.numNights)
    .reduce((a, c) => a + c);

  return sumOfNights;
};

export const sumAllSNV = (extras, siteNightVoucherExtraName) => {
  if (!extras) return 0;
  const snvs = extras.filter(e => e.name === siteNightVoucherExtraName);

  const numberOfSnvs = snvs.map(obj => obj.quantity).reduce((a, b) => a + (b || 0), 0);
  return numberOfSnvs;
};
