import styled, { css } from 'styled-components';

import Badge from './Badge';

export default styled(Badge)`
  position: relative;

  ${({ theme }) => `
    background-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    color: ${theme.COLOR_WHITE};
  `}

  ${({ position, theme }) => css`
    &:before {
      border-${position}-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    }
  `}

`;
