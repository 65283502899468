import styled from 'styled-components';

import { above } from '../../../styles/libs';

export default styled.div`
  display: none;
  position: sticky;
  top: 0;
  flex: 0 0 ${({ size }) => size};
  width: ${props => (props.orientation === 'horizontal' ? '100vw' : props.size)};
  max-width: 100vw;
  height: ${props => (props.orientation === 'vertical' ? '100vh' : props.size)};
  max-height: 100vh;

  ${({ theme }) => above(theme.TABLET)`
    display: block;
  `};
`;

export const Handle = styled.button.attrs(props => ({
  type: 'button',
}))`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
  width: 100%;
  height: 100%;
`;
