import styled from 'styled-components';
import { above, below } from '../../styles/libs';

import Text from '../ui/Text';
import { ButtonClose as ButtonCloseStyled } from '../PopUp/PopUp.styles';

export const Outer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ theme }) => below(theme.TABLET)`
    padding-top: 1rem;
  `}
`;

export const Content = styled.div`
  position: relative;
  padding: 1.75rem 1.75rem 0.5rem;
`;

export const Close = styled(ButtonCloseStyled)`
  ${({ theme }) => above(theme.TABLET)`
    display: none;
  `}
`;

export const Header = styled.header`
  ${({ theme }) => above(theme.TABLET)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const Quote = styled(Text)`
  font-size: 0.875rem;
  padding-bottom: 0.1875rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -0.75rem;
`;
