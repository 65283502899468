import gql from 'graphql-tag';

import quoteFragments from './quoteFragments';

export default gql`
${quoteFragments}

mutation CreateQuoteAmend($input: CreateQuoteAmendInput) {
  quoteResponse: QuoteUpdate(input: $input)
    @rest(
      type: "QuoteUpdate"
      path: "BookingAmendment/CreateBookingAmendmentQuote"
      method: "POST"
    ) {
      quote {
        basketState
        balanceDueDate
        basketHoldExpiryTimestamp
        basketHoldMaxTimestamp
        basketHoldStartTimestamp
        bookingReference
        cancellationPenalty
        cancellationPenaltyApplicableFrom
        campsiteBookings {
          id
          campsite {
            id
            name
            address {
              country
              county
              addressLines
              postCode
            }
            rating
            siteCode
            type
          }
          pitches {
            ...Pitch
          }
        }
        crossingBookings @type(name: "CrossingBookings") {
          ...CrossingBooking
        }
        deposit
        departureDate
        errorCode
        extras {
          ...Extra
        }
        quoteId
        isBookingAmendmentQuote
        isMemberQuote
        membership
        membershipType
        nonMemberSupplementsCost
        outfit {
          externalId
          id
          towHeight
          towLength
          towType
          towMake
          towModel
          vehicleHeight
          vehicleLength
          vehicleMake
          vehicleModel
          vehicleReg
          vehicleType
        }
        partyMembers {
          ...PartyMemberFrag
        }
        phoneNumber {
          countryPrefix
          number
        }
        product
        status
        totalPrice
        totalPriceIncludesNonMemberSupplements
      }
    }
}
`;
