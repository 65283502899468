export default () => {
  if (process.browser) {
    if (navigator.platform) {
      // iOS
      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
      }

      // iPad OS has the same platform and useragent as Mac OS so detect touch support
      if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints) {
        return true;
      }
    }
  }
  return false;
};
