import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ButtonLink from '../ui/Button/ButtonLink';
import Text from '../ui/Text';
import { dictionaryItem } from '../../hocs/withDictionary';

const Container = styled.div`
  padding: 0.75rem 0;
  margin: 2rem 0;
  border-top: solid 1px ${({ theme }) => theme.COLOR_GRAY};
  border-bottom: solid 1px ${({ theme }) => theme.COLOR_GRAY};
`;

const DialogLogin = ({ onLoginClick }) => (
  <Container>
    <Text dictionary={dictionaryItem('DialogMembership', 'Login')} />
    <ButtonLink dictionary={dictionaryItem('DialogMembership', 'Login')} onClick={onLoginClick} />
  </Container>
);

DialogLogin.propTypes = {
  onLoginClick: PropTypes.func,
};

DialogLogin.defaultProps = {
  onLoginClick: undefined,
};

export default DialogLogin;
