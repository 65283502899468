import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../hocs/withDictionary';

import { ButtonBordered } from '../ui/Button';
import Modal, { ModalClose, ModalContent, ModalIcon } from '../ui/Modal';
import Title from '../ui/Title';
import Text from '../ui/Text';

import svgError from '../../static/images/icons/Error.svg';

import { Row, Col } from '../ui/Grid';

function DialogVouchers({ handleClose, open }) {
  return (
    <Modal active={open} overlay>
      <ModalContent size="small">
        <ModalClose onClick={handleClose} />
        <ModalIcon icon={svgError} />

        <Title
          align="center"
          dictionary={dictionaryItem('DialogVouchers')}
          tag={3}
          size={3}
          marginBottom
        />

        <Text
          align="center"
          dictionary={dictionaryItem('DialogVouchers')}
          marginBottom="2rem"
          size="1rem"
        />

        <Row>
          <Col>
            <ButtonBordered
              block
              dictionary={dictionaryItem('DialogVouchers')}
              onClick={handleClose}
            />
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
}

DialogVouchers.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

DialogVouchers.defaultProps = {
  open: false,
};

export default memo(DialogVouchers);
