import styled from 'styled-components';

import { above } from '../../styles/libs';

export default styled.button`
  outline: none;
  background-color: transparent;
  border: 0;
  padding: 0 0.625rem;
  cursor: pointer;

  ${({ theme }) => above(theme.TABLET)`
    display: none;
  `}
`;
