import { cloneDeep } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';
import { defaults as defaultQuote } from '../../resolvers/quote';
import PRODUCT_TYPES from '../../config/products';
import EXTRA_TYPES from '../../config/extraTypes';
import EVENT_TYPES from '../../config/eventTypes';
import { personTypes } from '../../config/personType';
import { getAge } from '../../components/Guests/guestHelpers';
import { TOW_TYPES } from '../../config/outfits';

export const getDefaultOutfit = () => ({
  __typename: 'Outfit',
  towHeight: null,
  towLength: null,
  towType: TOW_TYPES.NONE,
  vehicleHeight: null,
  vehicleLength: null,
  vehicleType: '',
  vehicleReg: null,
});

export const generateDefaultPartyMembers = (configuration, isOverseas) => {
  if (!configuration) {
    return [];
  }
  const productCode = isOverseas ?
    PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT;
  const product = configuration.products?.find((productItem) => (
    productItem.productCode === productCode
  ));
  if (!product) {
    return [];
  }
  const ADULT_ID = personTypes.ADULT;
  const CHILD_ID = personTypes.CHILDREN;
  const INFANT_ID = personTypes.INFANT;

  const generatePartyByType = (type) => Array.from(
    Array(product.partyConfig.find(
      item => item.type === type,
    )?.defaultValue ?? 0).keys(),
  );

  const adultsMap = generatePartyByType(ADULT_ID);
  const childrenMap = generatePartyByType(CHILD_ID);
  const infantMap = generatePartyByType(INFANT_ID);
  const result = [
    ...adultsMap.map(() => ({
      __typename: 'PartyMember',
      personId: 0,
      type: ADULT_ID,
      age: null,
      specialRequests: [],
    })),
    ...childrenMap.map(() => ({
      __typename: 'PartyMember',
      personId: 0,
      type: CHILD_ID,
      age: null,
      specialRequests: [],
    })),
    ...infantMap.map(() => ({
      __typename: 'PartyMember',
      personId: 0,
      type: INFANT_ID,
      age: null,
      specialRequests: [],
    })),
  ];
  return result;
};

export function getPartyMembersFromQuote(partyMembers, quoteDepartureDate) {
  return partyMembers.map(partyMember => ({
    ...partyMember,
    age: partyMember.type === personTypes.ADULT ?
      null : getAge(partyMember.dateOfBirth, quoteDepartureDate),
  }));
}

function payloadFromQuery(
  query, configuration, isOverseas,
) {
  let bookingComponent = {};

  // Set up quote for a campsite booking
  if (query.siteCode) {
    bookingComponent = {
      guests: [],
      pitchTypeId: null,
      siteCode: query.siteCode,
    };
  }

  return {
    ...bookingComponent,
    end: query.end,
    extras: [],
    offers: [],
    outfit: getDefaultOutfit(),
    partyMembers: generateDefaultPartyMembers(configuration, isOverseas),
    start: query.start,
    eventType: query.eventType || EVENT_TYPES.TOURING.id,
  };
}

function checkForPreExistingSpecialRequirementsInPayload(quote, componentId) {
  let matchingPitchBooking;

  // optional chaining causing eslint to bork
  // eslint-disable-next-line no-unused-expressions
  quote?.campsiteBookings?.forEach((campsiteBooking) => {
    const matchingPitch = campsiteBooking.pitches.find(
      pitch => pitch.id === componentId,
    );

    if (matchingPitch) {
      matchingPitchBooking = matchingPitch;
    }
  });

  if (matchingPitchBooking?.specialRequests) {
    return matchingPitchBooking.specialRequests;
  }

  return [];
}

function applyQuoteToPayload(quote = {}, configuration, isOverseas, componentId, booking) {
  // GraphQL returns null for missing data
  const clonedQuote = cloneDeep(quote);

  if (quote === null) return {};

  const outfit = (quote?.isBookingAmendmentQuote && booking?.outfit) ?
    booking.outfit : quote.outfit;

  if (outfit) {
    if (outfit.vehicleReg === '') outfit.vehicleReg = null;
    if (outfit.vehicleLength === 0) outfit.vehicleLength = null;
    if (outfit.towLength === 0) outfit.towLength = null;
    if (outfit.vehicleHeight === 0) outfit.vehicleHeight = null;
    if (outfit.towHeight === 0) outfit.towHeight = null;
  }

  // if we have an empty quote (such as when we have removed item from basket)
  // we want to merge the default TL one person with the usual IBE 2 persons.
  if (
    clonedQuote.campsiteBookings
    && !clonedQuote.campsiteBookings.length
    && clonedQuote.crossingBookings
    && !clonedQuote.crossingBookings.length
    && clonedQuote.partyMembers
    && clonedQuote.partyMembers.length
  ) {
    const mergedPartyMembers = generateDefaultPartyMembers(
      configuration, isOverseas,
    );
    mergedPartyMembers[0].personId = clonedQuote.partyMembers[0].personId;
    clonedQuote.partyMembers = mergedPartyMembers;
  }

  const payload = {
    guests: clonedQuote.guests,
    partyMembers: getPartyMembersFromQuote(clonedQuote.partyMembers, clonedQuote.departureDate),
    outfit: outfit || {},
    quoteId: clonedQuote.quoteId,
    specReq: checkForPreExistingSpecialRequirementsInPayload(clonedQuote, componentId),
  };

  return payload;
}

export function getPitch(quote, query, pitchId) {
  if (!quote?.campsiteBookings?.length) {
    return null;
  }
  const componentId = (pitchId && `${pitchId}`) || query.componentId;
  const campsiteBookingItem = quote.campsiteBookings.find(
    campsiteBooking => campsiteBooking.id?.includes(componentId),
  ) || quote.campsiteBookings.find(
    campsiteBooking => campsiteBooking.campsite?.id === query.campsiteId,
  );
  const pitch = campsiteBookingItem?.pitches?.find(
    (pitchItem) => pitchItem.id === componentId,
  ) || campsiteBookingItem?.pitches?.[0];

  if (pitch) {
    return pitch;
  }

  return null;
}

export function getGuests(guests) {
  if (!guests?.length) {
    return [];
  }
  return guests.map(
    (guest) => ({
      ...guest,
      price: guest.guestAmount,
      // BED returns the same personId for all guest associated with lead booker
      personId: undefined,
      tempId: uuid(),
    }),
  );
}

export function guestsFromQuote(quote, query) {
  const pitch = getPitch(quote, query);
  if (!pitch) {
    return [];
  }
  return getGuests(pitch.guests);
}

export function arrivalTimeFromQuote(quote, query) {
  const pitch = getPitch(quote, query);
  if (!pitch || !pitch?.arrivalDateTime) {
    return undefined;
  }
  const arrivalHour = moment(pitch.arrivalDateTime).format('H');
  return Number(arrivalHour);
}

export const getAdvancedPitchPayload = (pitch, quote, query) => {
  if (!pitch || !quote?.quoteId || !pitch?.parentPitchId) {
    return {};
  }
  const parentPitch = getPitch(quote, query, pitch.parentPitchId);
  if (!parentPitch) {
    return {};
  }
  return {
    minDate: parentPitch.bookingDates?.fromDate,
    maxDate: parentPitch.bookingDates?.toDate,
    parentPitchId: pitch.parentPitchId,
    mainPayload: {
      partyMembers: getPartyMembersFromQuote(quote.partyMembers, quote.departureDate).filter(
        (member) => member.comment !== 'AdvancedGuest',
      ),
      outfit: quote.outfit || {},
    },
  };
};

export function getPayloadFromQuoteAndQuery(
  query, quote = defaultQuote, membershipType,
  configuration, isOverseas, componentId, booking,
) {
  const payload = {
    ...payloadFromQuery(query, configuration, isOverseas),
    ...applyQuoteToPayload(quote, configuration, isOverseas, componentId, booking),
    guests: guestsFromQuote(quote, query),
    supplements: [],
    arrivalTime: arrivalTimeFromQuote(quote, query),
    membershipType: quote?.membershipType ||
      membershipType ||
      getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember),
  };

  const pitch = quote ? getPitch(quote, query) : null;
  // If editing an existing campsite booking
  if (pitch && quote) {
    payload.id = pitch.id;
    payload.pitchTypeId = pitch.code;
    payload.pitchName = pitch.type;
    payload.start = pitch.bookingDates.fromDate;
    payload.end = pitch.bookingDates.toDate;
    payload.supplements = pitch.supplements;
    payload.guests = getGuests(pitch.guests);
    payload.partyMembers = (!query.bookingId && pitch.partyMemberReference?.length) ?
      payload.partyMembers?.filter(
        (member) => member.comment === (pitch.parentPitchId ? 'AdvancedGuest' : '') &&
          pitch.partyMemberReference?.includes(member.personId),
      ) : payload.partyMembers;
    payload.outfit = pitch.parentPitchId ? pitch.outfit : payload.outfit;
    payload.eventType = pitch.eventType;
    payload.cancelReasonId = pitch.cancelReasonId;
  }
  if (!query.componentId && !query.bookingId) {
    payload.id = undefined;
    payload.start = query.start;
    payload.end = query.end;
    payload.guests = [];
    payload.pitchTypeId = undefined;
    payload.pitchName = undefined;
    payload.supplements = [];
    payload.eventType = query.eventType || EVENT_TYPES.TOURING.id;
  }
  return {
    ...payload,
    ...getAdvancedPitchPayload(pitch, quote, query),
  };
}

export function getCampingPayloadFromPitches(quote, payload, pitches) {
  return pitches.map((pitch) => ({
    ...payload,
    arrivalTime: moment(pitch.arrivalDateTime).format('H'),
    end: pitch.bookingDates?.toDate,
    eventType: pitch.eventType,
    extras: [],
    guests: [],
    id: pitch.id,
    maxDate: payload.end,
    minDate: payload.start,
    offers: [],
    outfit: pitch.outfit,
    parentPitchId: payload.id,
    partyMembers: getPartyMembersFromQuote(quote.partyMembers, quote.departureDate).filter(
      (member) => member.comment !== 'AdvancedGuest',
    ),
    pitchName: pitch.type,
    pitchTypeId: pitch.code,
    quoteId: quote?.quoteId,
    specReq: pitch.specialRequests,
    start: pitch.bookingDates?.fromDate,
    supplements: pitch.supplements,
  }));
}

export function getCampingPayloadFromQuote(query, quote, payload) {
  if (!quote?.quoteId || !quote?.campsiteBookings?.length || !query.componentId || !payload?.id) {
    return [];
  }
  const pitches = quote.campsiteBookings.map(booking => booking.pitches.filter(
    (pitch) => Number(pitch.parentPitchId) === Number(payload.id),
  )).flat();
  return getCampingPayloadFromPitches(quote, payload, pitches);
}

export function isQuoteEmpty(quote) {
  if (!quote || !quote.quoteId) {
    return true;
  }
  const { extras, crossingBookings, campsiteBookings } = quote;
  return !extras?.length
    && !campsiteBookings?.length
    && !crossingBookings?.length;
}

export function quoteHasNonMemberExclusiveProducts(quote) {
  if (!quote || !quote?.quoteId) {
    return false;
  }
  return quote.campsiteBookings?.some(
    (booking) => booking.pitches.some(pitch => !pitch.isMemberExclusive),
  );
}

export function getQuoteOsnvQuantity(quote) {
  return quote.extras?.find(extra => extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER)?.quantity;
}

export function quoteUpdatedOsnv(prevQuote, quote) {
  if (!prevQuote || !quote) {
    return false;
  }
  return prevQuote.extras?.length !== quote.extras?.length ||
    getQuoteOsnvQuantity(prevQuote) !== getQuoteOsnvQuantity(quote);
}
