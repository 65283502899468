import styled from 'styled-components';

import RichTextComponent from '../ui/RichText';

export const RichText = styled(RichTextComponent)`
  width: 100%;
  max-width: 36rem;
  margin-bottom: 3rem;
  margin-top: 1rem;

  h2 {
    font-size: 1.25rem;
  }

  ul {
    padding: 0;
    list-style: none;
    text-align: left;

    li {
      font-weight: bold;
      padding-left: 2rem;
      margin: 0.5rem 0;
      position: relative;
      line-height: 1.75em;

      &::before {
        content: "";
        display: inline-block;
        background-image: url("/static/images/icons/ListItemTick.svg");
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.25em;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  p {
    font-size: 0.875rem;
    margin: 1.5em 0;
  }
`;

export const DialogFooter = styled.div`
  margin-top: 2rem;
`;

export const DialogInfo = styled.div`
  display: flex;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: solid 1px ${({ theme }) => theme.COLOR_GRAY};
`;

export default null;
