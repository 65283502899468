import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import Overlay from '../Modal/Overlay';

import StyledDrawer from './Drawer.style';

function getOrientation(direction) {
  if (direction === 'left' || direction === 'right') return 'vertical';
  return 'horizontal';
}

class Drawer extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    callback: PropTypes.func,
    direction: PropTypes.string,
    inView: PropTypes.bool,
    isBookingWidget: PropTypes.bool,
    forwardRef: PropTypes.shape({}),
    maxWidth: PropTypes.string,
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    open: PropTypes.bool,
    onRequestChange: PropTypes.func.isRequired,
    position: PropTypes.string,
    width: PropTypes.string,
    zIndex: PropTypes.number,
    overlay: PropTypes.bool,
    useMarginForAnimation: PropTypes.bool,
  };

  static defaultProps = {
    callback() { },
    className: null,
    open: false,
    direction: 'left',
    forwardRef: null,
    inView: false,
    isBookingWidget: false,
    maxWidth: 'auto',
    offset: null,
    position: 'relative',
    width: 'auto',
    zIndex: 1,
    overlay: false,
    useMarginForAnimation: false,
  };

  timeoutId = 0;

  handleResize = debounce(() => {
    if (this.drawerRef.current === null) return;
    this.setState({ animate: false });
    this.forceUpdate();
    this.enableAnimations();
  }, 250);

  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };

    this.drawerRef = this.props.forwardRef ? this.props.forwardRef : React.createRef();
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize, { passive: true });
    this.enableAnimations();
  }

  componentWillUnmount() {
    if (this.handleResize.cancel) this.handleResize.cancel();
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.timeoutId);
  }

  enableAnimations = () => {
    this.timeoutId = setTimeout(() => {
      this.setState({ animate: true });
    }, 0);
  }

  render() {
    const orientation = getOrientation(this.props.direction);
    const width = this.drawerRef.current ? this.drawerRef.current.clientWidth : null;

    const contentProps = {
      className: this.props.className,
      useMarginForAnimation: this.props.useMarginForAnimation,
      direction: this.props.direction,
      offset: this.props.offset,
      orientation,
      maxWidth: this.props.maxWidth,
      position: this.props.position,
      width,
      zIndex: this.props.zIndex,
    };

    const drawerProps = {
      inView: this.props.inView,
      open: this.props.open,
      overlay: this.props.overlay,
      animate: this.state.animate,
      isBookingWidget: this.props.isBookingWidget,
    };

    const mapEvents = {
      handleDrawerToggle: this.props.onRequestChange,
    };

    return (
      <Fragment>
        <StyledDrawer
          {...contentProps}
          {...drawerProps}
          ref={this.drawerRef}
          data-hide-on-modal
        >
          {React.Children.map(this.props.children, child => child && React.cloneElement(child, {
            ...mapEvents,
            ...drawerProps,
          }))}
        </StyledDrawer>
        {this.props.overlay && this.props.open &&
          <Overlay
            onClick={() => this.props.onRequestChange(false)}
            zIndex={this.props.zIndex - 1}
          />
        }
      </Fragment>
    );
  }
}

export default Drawer;
