import React from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from '../ui/Loading';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BasketLoader = () => (
  <Outer>
    <LoadingSpinner />
  </Outer>
);

export default BasketLoader;
