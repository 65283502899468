import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { getDictionaryItem } from '../../hocs/withDictionary';

import Modal, { ModalContent, ModalClose } from '../ui/Modal';
import Title from '../ui/Title';
import Text from '../ui/Text';
import { Icon } from '../ui';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import {
  Actions, Action, ActionBrand, AlertIcon,
} from '../PopUp/PopUp.styles';
import theme from '../../styles/config/theme';

import svgInfo from '../../static/images/icons/Info.svg';

import svgError from '../../static/images/icons/Error.svg';
import svgCheck from '../../static/images/icons/Check.svg';
import svgCircleClose from '../../static/images/icons/CircleClose.svg';
import { RichText, DialogFooter, DialogInfo } from './Dialog.style';
import ButtonLink from '../ui/Button/ButtonLink';
import IbePropTypes from '../../IbePropTypes';
import DialogLogin from './DialogLogin';
import { DIALOG_MEMBERSHIP_STATUS } from '../../config/dialogMembership';
import isLoggedIn from '../../lib/isLoggedIn';
import { dataLayerManager } from '../../lib/dataLayer';

function MembershipDialog({
  campsiteId,
  client,
  crossing,
  dialogInfoDictionary,
  open,
  benefits,
  showBenefits,
  primaryAction,
  secondaryAction,
  showPrimaryButton,
  showSecondaryButton,
  siteCode,
  title,
  description,
  primaryActionText,
  secondaryActionText,
  footerActionText,
  status,
  currency,
  currencyString,
  amount,
  amountSecondary,
  amountString,
  loading,
  handleLogin,
  handleFooterAction,
  membershipCost,
  onClose,
}) {
  const richText = getDictionaryItem(
    client,
    benefits,
  );

  const formatAmount = (value) => {
    if (typeof value !== 'number') {
      return '0.00';
    }
    return value.toFixed(2);
  };

  const dialogTitle =
    getDictionaryItem(client, title)
      .replace(currencyString, currency)
      .replace(amountString, formatAmount(amount));

  const dialogDescription =
    getDictionaryItem(client, description)
      .replace(currencyString, currency)
      .replace(amountString, formatAmount(amountSecondary ?? amount));

  const getDialogTheme = () => {
    if (status === DIALOG_MEMBERSHIP_STATUS.WARNING) {
      return {
        icon: svgError,
        color: theme.COLOR_AMBER_ORANGE,
      };
    }
    if (status === DIALOG_MEMBERSHIP_STATUS.ERROR) {
      return {
        icon: svgCircleClose,
        color: theme.COLOR_BERRY_RED_DARK,
      };
    }
    return {
      icon: svgCheck,
      color: theme.COLOR_BRAND_PRIMARY,
    };
  };

  const dialogTheme = getDialogTheme();

  useEffect(() => {
    if (open) {
      dataLayerManager.pushProductDetails(dataLayerManager.category.MEMBERSHIP, {
        price: amountSecondary || amount || membershipCost,
        id: campsiteId,
        siteCode,
        crossing,
      });
    }
  }, [open]);

  return (
    <Modal active={open} overlay>
      <ModalContent size="small">
        {onClose && <ModalClose type="Button" onClick={onClose} />}
        <AlertIcon icon={dialogTheme.icon} color={dialogTheme.color} />

        <Title
          align="center"
          tag={3}
          size={3}
          marginBottom
          color={dialogTheme.color}
        >
          {dialogTitle}
        </Title>
        <Text
          align="center"
          marginBottom="2rem"
          size="1rem"
        >
          {dialogDescription}
        </Text>

        {loading && <LoadingSpinner />}
        <Actions align="center">
          {showSecondaryButton && (
            <Action
              dictionary={secondaryActionText}
              onClick={secondaryAction}
              buttonStyle={{ width: '100%' }}
              disabled={loading}
            />
          )}
          {showPrimaryButton && (
            <ActionBrand
              dictionary={primaryActionText}
              onClick={primaryAction}
              maxWidth="14rem"
              disabled={loading}
            />
          )}
        </Actions>

        {footerActionText ?
          (
            <DialogFooter>
              <ButtonLink
                dictionary={footerActionText}
                onClick={handleFooterAction}
                color={dialogTheme.color}
              />
            </DialogFooter>
          ) : (!!handleLogin && !isLoggedIn() && <DialogLogin onLoginClick={handleLogin} />)
        }

        {dialogInfoDictionary &&
        <DialogInfo>
          <Icon
            color={theme.COLOR_BRAND_PRIMARY}
            icon={svgInfo}
            marginRight="0.5rem"
          />
          <Text
            dictionary={dialogInfoDictionary}
            weight="bold"
            align="left"
            size="0.8rem"
          />
        </DialogInfo>}

        {showBenefits && <RichText>{richText}</RichText>}
      </ModalContent>
    </Modal>
  );
}

MembershipDialog.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  dialogInfoDictionary: PropTypes.string,
  open: PropTypes.bool,
  benefits: PropTypes.string,
  showBenefits: PropTypes.bool,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  showPrimaryButton: PropTypes.bool,
  showSecondaryButton: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  primaryActionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  footerActionText: PropTypes.string,
  status: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.number,
  amountSecondary: PropTypes.number,
  currencyString: PropTypes.string,
  amountString: PropTypes.string,
  loading: PropTypes.bool,
  handleLogin: PropTypes.func,
  handleFooterAction: PropTypes.func,
  campsiteId: PropTypes.string,
  crossing: PropTypes.bool,
  siteCode: PropTypes.string,
  membershipCost: PropTypes.number,
  onClose: PropTypes.func,
};

MembershipDialog.defaultProps = {
  benefits: '',
  dialogInfoDictionary: '',
  showBenefits: true,
  open: false,
  primaryAction: undefined,
  secondaryAction: undefined,
  showPrimaryButton: true,
  showSecondaryButton: false,
  title: '',
  description: '',
  primaryActionText: '',
  secondaryActionText: '',
  footerActionText: '',
  currency: '£',
  amount: 0,
  amountSecondary: undefined,
  status: DIALOG_MEMBERSHIP_STATUS.SUCCESS,
  currencyString: '{{Currency}}',
  amountString: '{{Amount}}',
  loading: false,
  handleLogin: undefined,
  handleFooterAction: null,
  campsiteId: '',
  crossing: false,
  siteCode: '',
  membershipCost: 0,
  onClose: undefined,
};

export default withApollo(MembershipDialog);
