import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import DOMPurify from 'isomorphic-dompurify';

import { Wrapper, UpgradeButton, Message } from './BasketPackageUpgrade.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import QuoteAcceptComponent from '../Quote/QuoteAcceptComponent';

import GET_DICTIONARY_ITEM from '../../hocs/withDictionary/getDictionaryItem.gql';

const propTypes = {
  componentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPackageUpgradeClick: PropTypes.func.isRequired,
  saving: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const BasketPackageUpgrade = props => (
  <Wrapper>
    <Query query={GET_DICTIONARY_ITEM} variables={{ key: 'BasketPackageUpgrade__Prompt' }}>
      {({ data }) => {
        if (!data || !data.dictionaryItem) return null;

        let promptHtml = data.dictionaryItem && data.dictionaryItem.value;
        const savingHtml = `<span class="highlight">&#163;${props.saving}</span>`;
        promptHtml = promptHtml.replace('{{saving}}', savingHtml);

        return (
          <Message>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(promptHtml) }} />
          </Message>
        );
      }}
    </Query>

    <QuoteAcceptComponent
      componentIds={props.componentIds}
      onSuccess={props.onPackageUpgradeClick}
    >
      <UpgradeButton
        dictionary={dictionaryItem('BasketPackageUpgrade', 'AcceptPackage')}
        size="small"
      />
    </QuoteAcceptComponent>
  </Wrapper>
);

BasketPackageUpgrade.propTypes = propTypes;

export default BasketPackageUpgrade;
