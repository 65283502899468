import styled, { css } from 'styled-components';

const config = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

const sizes = {
  large: css`
    font-size: 0.75rem;
    padding: 0.25rem 0.625em;
  `,
  medium: css`
    font-size: 0.625rem;
    padding: 0 0.25rem;
  `,
};

function calculatePosition(position) {
  const isVertical = ['left', 'right'].includes(position);

  const direction = isVertical ? 'top' : 'left';
  const translate = isVertical ? 'translateY' : 'translateX';

  return `
    ${direction}: 50%;
    transform: ${translate}(-50%);
  `;
}

export default styled.span`
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;

  ${({ size }) => sizes[size || 'medium']}

  ${({ position }) => position && css`
    margin-${config[position]}: 0.5rem;

    &:before {
      content: '';
      position: absolute;
      ${[config[position]]}: -4px;
      ${calculatePosition(position)}
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-${config[position]}: 0;
    }
  `}
`;
