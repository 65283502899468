import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import formatPrice from '../../lib/formatPrice';
import { Text } from '../ui';
import BasketExtraStyled, { Header, Price } from './BasketExtra.style';
import { RemoveButton } from './BasketItem.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import testingAttr from '../../lib/testingAttr';

const BasketMembership = ({ totalPrice, handleRemoveMembership }) => (
  <BasketExtraStyled>
    <Header>
      <Text
        weight="bold"
        {...testingAttr('basket-membership-text')}
        dictionary={dictionaryItem('BasketMembership')}
      />
      <Price>
        <b>{formatPrice(totalPrice)}</b>
      </Price>
    </Header>
    <Header>
      <RemoveButton onClick={handleRemoveMembership} />
    </Header>
  </BasketExtraStyled>
);

BasketMembership.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  handleRemoveMembership: PropTypes.func.isRequired,
};

export default withTheme(BasketMembership);
