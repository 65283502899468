import React from 'react';
import PropTypes from 'prop-types';

import StyledBadge from './Badge.style';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  position: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  position: null,
  size: 'medium',
};

function Badge({
  children,
  className,
  position,
  size,
}) {
  return (
    <StyledBadge className={className} position={position} size={size}>
      {children}
    </StyledBadge>
  );
}

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
