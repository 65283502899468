import styled, { css } from 'styled-components';

import detectIE from '../../lib/detectIE';

import ButtonBrandStyled from '../ui/Button/ButtonBrand.style';

// Be careful of increasing  size here as it can cause checkout
// to wrap, causing no content display on screen.
export default styled(ButtonBrandStyled)`
  width: 100%;
  ${!!detectIE() && css`
    height: 3.175rem;
  `}
  font-size: 0.875rem;
  padding: 0;
`;
