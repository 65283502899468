import React, { Component, Fragment } from 'react';
import { withRouter } from 'next/router';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';
import { areRangesOverlapping, format } from 'date-fns';
import QueryString from 'query-string';
import moment from 'moment';

import isLoggedIn, { isLoggedInMember } from '../../lib/isLoggedIn';
import { returnSupplierLogo } from '../../lib/helpers/crossings';
import updateRouterQuery from '../../lib/updateRouterQuery';
import isITXApplicable, { sumAllNights, sumAllSNV } from '../../lib/isITXApplicable';
import { DATE_FORMAT_DEFAULT } from '../../config/locale';

import BasketExtra from './BasketExtra';
import BasketHeader from './BasketHeader';
import BasketItem from './BasketItem';
import BasketSummary from './BasketSummary';
import BookingBasketSummary from './BookingBasketSummary';
import BasketUpSell from './BasketUpSellPassivePrompt';
import BasketPackageUpgrade from './BasketPackageUpgrade';
import BookingBasketHeader from '../ManageMyBookings/BookingBasketHeader';
import BasketLoader from './BasketLoader';
import BasketImportantInformation from './BasketImportantInformation';

import BasketItemCampsite from './BasketItemCampsite';
import BasketItemFerry from './BasketItemFerry';

import { Container } from './BasketContent.style';
import { MiniBasketItem, AcceptButton } from './BasketItem.style';

import GET_USER from '../../config/graphql/getUser';
import GET_PERSIST_BASKET_VALIDATION from './graphql/getPersistBasketValidation';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import { BasketPartyMembers } from '.';
import * as QuoteChangeTypes from '../../constants/QuoteChangeTypes';
import ItxProvider from '../../lib/helpers/Itx/ItxProvider';

import IbePropTypes from '../../IbePropTypes';
import { getDictionaryItem, dictionaryItem } from '../../hocs/withDictionary';
import BasketOverlappingSiteDatesPassivePrompt from './BasketOverlappingSiteDatesPassivePrompt';
import BasketScopedOverlappingSitesType from '../../config/basketScopedOverlappingSitesType';
import BasketMembership from './BasketMembership';
import quoteMembershipTypes, { getENumWithValue, isMembershipShownInBasket } from '../../config/quoteMembershipTypes';
import { updateQuoteCache } from '../../resolvers/quote';

import withStateMutation from '../../hocs/withStateMutation';
import UPDATE_QUOTE from '../Quote/graphql/updateQuote';
import CREATE_AMEND_QUOTE from '../Quote/graphql/createAmendQuote';
import {
  getMembershipDialogProps, getRemoveDialogType,
} from '../../lib/helpers/dialogMembership';
import { isQuoteEmpty } from '../../lib/helpers/quote';
import { getProductCodeByName } from '../../lib/campsiteTypes';
import { DIALOG_MEMBERSHIP_TYPE } from '../../config/dialogMembership';
import MembershipDialog from '../Dialog/MembershipDialog';
import routes from '../../constants/routes';
import { BASKET_STATES } from '../../config/products';
import { LoadingSpinner } from '../ui/Loading';
import RESET_SESSION from '../Quote/graphql/resetSession';
import { RENEWAL_STATUSES } from '../../config/membershipStatus';
import FetchPolicy from '../../constants/FetchPolicy';
import { redirectViaRouterToLoginWithBasketOpen, addToBasketRedirect, sortBookings } from './helpers';
import NoticeModal from '../ui/Modal/NoticeModal/NoticeModal';
import RichText from '../ui/RichText';
import Text from '../ui/Text';
import { getAmendRestrictionReason, AMEND_KEYS, AMEND_RESTRICTIONS } from '../../config/amendRestrictions';
import EXTRA_TYPES from '../../config/extraTypes';
import { ids } from '../../config/campsiteTypes';
import { AMEND_TYPES } from '../../config/amend';
import {
  AMEND_MODALS, getModalProps,
} from '../../lib/helpers/amend';
import { dataLayerManager } from '../../lib/dataLayer';

class BasketContent extends Component {
  static propTypes = {
    activeQuote: PropTypes.shape(IbePropTypes.quote),
    booking: PropTypes.shape(IbePropTypes.booking),
    count: PropTypes.number.isRequired,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    user: PropTypes.shape(IbePropTypes.user),
    userLoading: PropTypes.bool,
    handleFullClick: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    isFull: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    quoteLoading: PropTypes.bool.isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    toggleBasket: PropTypes.func.isRequired,
    isBookingBasket: PropTypes.bool,
    isVisible: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    updateQuote: PropTypes.func,
    createAmendQuote: PropTypes.func,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
      COLOR_DANGER: PropTypes.string,
      COLOR_SUCCESS: PropTypes.string,
    }),
    totalValue: PropTypes.number,
    outstandingBalance: PropTypes.number,
    bcode: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(IbePropTypes.apolloError)]),
    quoteError: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(IbePropTypes.apolloError)]),
    isUpcoming: PropTypes.bool,
    partyDetails: PropTypes.string,
    isOverseasBooking: PropTypes.bool,
    handlePayBalance: PropTypes.func,
    query: PropTypes.shape(IbePropTypes.query),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    resetSession: PropTypes.func.isRequired,
    persistBasketValidation: PropTypes.bool,
    refetchUser: PropTypes.func,
  };

  static defaultProps = {
    activeQuote: null,
    booking: null,
    user: null,
    userLoading: false,
    persistBasketValidation: false,

    isBookingBasket: false,
    quote: {
      campsiteBookings: [],
      crossingBookings: [],
      id: '',
      extras: [],
    },
    theme: {
      COLOR_WHITE: '',
      COLOR_DANGER: '',
      COLOR_SUCCESS: '',
    },
    totalValue: undefined,
    outstandingBalance: undefined,
    bcode: undefined,
    error: undefined,
    quoteError: undefined,
    isUpcoming: false,
    partyDetails: '',
    isOverseasBooking: false,
    handlePayBalance: undefined,
    handleCancel: undefined,
    updateQuote: undefined,
    createAmendQuote: undefined,
    query: undefined,
    refetchUser: undefined,
    configuration: {
      defaultCampsiteLogoImage: '',
      itxPackageRules: {},
      ports: [],
      quoteChangeTypes: [],
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      hasUserAttemptedCheckout: props.persistBasketValidation,
      basketScopedOverlappingSitesType: BasketScopedOverlappingSitesType.OFF,
      membershipDialog: null,
      membershipChecked: false,
      amendRestriction: null,
      amendLoading: false,
      amendType: '',
      addOsnvLoading: false,
      componentToAmend: null,
      cancelPopUp: false,
      amendInProgress: null,
      amendStatusChecked: false,
      removeMembership: false,
      isInformationPopUpOpen: false,
    };
  }

  componentDidMount() {
    this.updateBasketScopedOverlappingSitesType();
  }

  componentDidUpdate(prevProps) {
    this.updateBasketScopedOverlappingSitesType();
    if (this.props.quote.quoteId || this.props.quote.bookingReference) {
      this.handlePendingCampsiteAdd();
      this.handleProductType();
      this.handlePendingValidateQuoteRedirectToCheckout(prevProps);
      const prevMembershipStatus = prevProps.user?.membershipStatus;
      if (prevMembershipStatus !== undefined &&
        prevMembershipStatus !== this.props.user?.membershipStatus) {
        this.recheckQuoteMembership(prevProps);
      }
    }
    if (this.props.quote.quoteId || prevProps.quote?.quoteId) {
      this.checkQuoteMembership(prevProps);
    }

    if (this.props.activeQuote?.isBookingAmendmentQuote &&
      !this.state.amendStatusChecked) {
      this.checkAmendmentStatus();
    }
  }

  canAmendGuard = (component) => {
    const { quote } = this.props;
    const { amendmentRestrictions } = quote ?? {};
    const amendRestriction =
      getAmendRestrictionReason(
        amendmentRestrictions, component.amendmentRestrictions, false, true,
      );
    if (amendRestriction) {
      this.setState({ amendRestriction });
      return false;
    }
    return true;
  }

  checkAmendmentStatus = () => {
    const { activeQuote, quote, router } = this.props;
    this.setState({
      amendStatusChecked: true,
    });
    const { amendIntent } = window.localStorage ?? {};
    const unallowedRoute = router.route.includes(routes.crossings) ||
      router.route.includes(routes.myBookings) || (router.route.includes(routes.sites) &&
      !router.query.bookingId);
    if ((quote?.isBookingAmendmentQuote || activeQuote?.isBookingAmendmentQuote) &&
      quote?.campsiteBookings?.length && unallowedRoute
      && amendIntent !== 'cancel' && !quote?.quoteId) {
      if (!amendIntent) {
        this.setState({
          amendInProgress: { bookingId: quote.bookingReference },
        });
      } else {
        const { campsite, pitches } =
          quote.campsiteBookings.find(({ id }) => id === amendIntent) ||
          quote.campsiteBookings[0];
        const pitch = pitches[0];
        this.setState({
          amendInProgress: {
            campsiteId: campsite.id,
            end: format(pitch.bookingDates.toDate, DATE_FORMAT_DEFAULT),
            start: format(pitch.bookingDates.fromDate, DATE_FORMAT_DEFAULT),
            siteCode: campsite.siteCode,
            bookingId: quote.bookingReference,
            isOverseas: campsite.type === ids.OVERSEAS_SITE,
            componentId: pitch.id,
          },
        });
      }
    }
  }

  recheckQuoteMembership = (prevProps) => {
    this.setState({
      membershipChecked: false,
    }, () => {
      this.checkQuoteMembership(prevProps);
    });
  }

  handleProductType = () => {
    const {
      query, quote, configuration,
    } = this.props;
    if (query) {
      const isOverseasQuery = query.isOverseas === 'true';

      const resetProps = {
        showResults: false,
        componentId: null,
        campsiteId: query.bookingId ? query.campsiteId : null,
        campsiteName: null,
        location: null,
        siteCode: null,
        activePin: null,
      };

      const isCrossing = document.location.href?.includes(routes.crossings);

      if (quote?.basketState === BASKET_STATES.OVERSEAS &&
        !isOverseasQuery && !isCrossing && !isQuoteEmpty(quote)) {
        updateRouterQuery(routes.sites, {
          isOverseas: 'true',
          types: configuration?.overseasSitesTabDefaultSearchPlaceTypes,
          ...resetProps,
        });
      } else if (quote?.basketState === BASKET_STATES.UK &&
        isOverseasQuery && !isCrossing && !isQuoteEmpty(quote)) {
        updateRouterQuery(routes.sites, {
          isOverseas: '',
          types: configuration?.ukSitesTabDefaultSearchPlaceTypes,
          ...resetProps,
        });
      }
    }
  }

  updateBasketScopedOverlappingSitesType = () => {
    let { basketScopedOverlappingSitesType } = this.state;

    // here we check if we have product configuration data and a product name in our quote.
    // then we can set the correct basketScopedOverlappingSitesType based on the found config.

    // BasketScoped means that the overlap is related to another item within the current basket.
    // not another item in another booking, this is handled separately.

    // the default is off, ergo 'Off'
    const products = this.props.configuration?.products;
    const basketScopedOverlappingSitesTypes = this.props.configuration?.overlappingSitesTypes;

    if (
      products?.length > 0
      && basketScopedOverlappingSitesTypes
      && this.props.quote
    ) {
      const productConfig = products.find(
        p => p.productName === this.props.quote.product,
      );

      if (productConfig && productConfig.overlappingSitesType !== undefined) {
        // confusingly productConfig.overlappingSitesType is the overlappingSitesTypes key
        const overlappingSitesTypeConfigData
          = basketScopedOverlappingSitesTypes.find(
            t => t.key === productConfig.overlappingSitesType,
          );

        if (overlappingSitesTypeConfigData?.value) {
          basketScopedOverlappingSitesType = overlappingSitesTypeConfigData.value;
        }
      }
    }

    if (basketScopedOverlappingSitesType !== this.state.basketScopedOverlappingSitesType) {
      const updatedState = { basketScopedOverlappingSitesType };

      if (!basketScopedOverlappingSitesType) {
        // reset if our user has updated basket validity
        updatedState.hasUserAttemptedCheckout = false;
        this.props.client.writeData({ data: { persistBasketValidation: false } });
      }

      this.setState(updatedState);
    }
  }

  handlePendingCampsiteAdd = async () => {
    const { client, query, toggleBasket } = this.props;
    const pendingCampsiteName = localStorage.pendingCampsiteAdd;
    const pendingLogin = localStorage.pendingLogin === 'true';
    if (pendingCampsiteName && (!pendingLogin || isLoggedIn())) {
      try {
        await localStorage.removeItem('pendingCampsiteAdd');
        addToBasketRedirect(client, query, pendingCampsiteName, toggleBasket);
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * When logging in in order to checkout rather than redirect directly to checkout after login
   * we need to (currently) return to sites, allow the existing functionality of updating
   * the quote once a member login has been detected. Then we check if the quote prevent
   * validation and if we also have the localStorage hook of pendingValidateQuoteRedirectToCheckout
   * we either open basket to show error or finally redirect.
   *
   * Really this is temporary patch, and we need more robust business logic to handle these types
   * of executing actions based on change of login status.
   */
  handlePendingValidateQuoteRedirectToCheckout = (prevProps) => {
    const loggedInMembershipType = getENumWithValue(quoteMembershipTypes.LoggedInMember);
    const userIsMember = isLoggedInMember(this.props.user);

    if (
      userIsMember
      && this.props.quote.membershipType === loggedInMembershipType
      && prevProps.quote.membershipType !== loggedInMembershipType
    ) {
      // check quote validation error
      const {
        quote: {
          campsiteBookings = [],
        } = {},
      } = this.props;

      const quoteHasPreventValidation = campsiteBookings.some(campsiteBooking => (
        campsiteBooking.pitches.some(
          pitch => !!pitch.preventBookingComponents?.length,
        )
      ));

      // ensure redirect or basket open push occurs after stack has completed.
      // this is because upon quote updating, useEffects elsewhere can use updateRouterQuery.
      setTimeout(() => {
        if (localStorage.pendingValidateQuoteRedirectToCheckout && !quoteHasPreventValidation) {
          // redirect
          this.props.router.push({
            pathname: routes.checkout,
          });
        } else {
          const query = QueryString.parse(window.location.search);
          query.basketOpen = true;
          updateRouterQuery(this.props.router.route, query);
        }
      }, 0);
      localStorage.removeItem('pendingValidateQuoteRedirectToCheckout');
    }
  }

  pushMembershipData = (prevProps) => {
    const { configuration, quote } = this.props;
    const currentMembership = quote?.membershipType;
    const previousMembership = prevProps?.quote?.membershipType;

    if ((previousMembership || currentMembership) && currentMembership !== previousMembership) {
      let membershipAdded =
        currentMembership === getENumWithValue(quoteMembershipTypes.MembershipByDD);
      if (
        previousMembership === getENumWithValue(quoteMembershipTypes.MembershipByDD)
      ) {
        membershipAdded = false;
      }
      const membershipCost = configuration?.membershipTypes?.directDebit?.costSingle;
      dataLayerManager.pushAddToCart(dataLayerManager.category.MEMBERSHIP, {
        price: membershipCost,
        quantity: 1,
        membershipType: getENumWithValue(quoteMembershipTypes.MembershipByDD),
      }, !membershipAdded);
    }
  }

  checkQuoteMembership = (prevProps) => {
    const { membershipChecked } = this.state;
    const {
      quote, updateQuote, user, userLoading, query, configuration,
    } = this.props;
    const membershipCost = configuration?.membershipTypes?.directDebit?.costSingle;

    if (quote?.quoteId || prevProps.quote?.quoteId) {
      this.pushMembershipData(prevProps);
    }
    if (!userLoading && quote?.quoteId && updateQuote && !membershipChecked && !query?.siteCode) {
      this.setState({
        membershipChecked: true,
      }, () => {
        const loggedInMembershipType = getENumWithValue(quoteMembershipTypes.LoggedInMember);
        const userIsMember = isLoggedInMember(user);
        // check here whether to tell the quote to update based on potential updated user login
        if (
          userIsMember
          && quote.membershipType !== loggedInMembershipType
          && !RENEWAL_STATUSES.includes(user?.membershipStatus)
        ) {
          // here we update the quote membership type based on user login
          this.handleUpdateMembership(loggedInMembershipType);
          return;
        }

        const membershipAddedAutomatically = localStorage.autoMember === 'true';
        if (membershipAddedAutomatically && !userIsMember) {
          localStorage.removeItem('autoMember');
          this.setState({
            membershipDialog: {
              type: DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_OVERSEAS_AUTO,
              primaryAction: this.handleCloseDialog,
              showSecondaryButton: false,
              handleLogin: this.handleLogin,
              amount: membershipCost,
            },
          });
        }
      });
    }
  }

  handleLogin = () => {
    redirectViaRouterToLoginWithBasketOpen(this.props.router);
  }

  handleCloseDialog = () => {
    this.setState({
      membershipDialog: null,
    });
  }

  handleUpdateMembership = async (membershipType) => {
    const { quote } = this.props;
    const { product } = quote;

    if ((quote?.quoteId || quote?.bookingReference) &&
      isQuoteEmpty(quote) &&
      membershipType === getENumWithValue(
        quoteMembershipTypes.NotLoggedInOrNonMember,
      )) {
      // Clears quote if membership is removed and basket is empty
      await this.props.resetSession();
      return;
    }

    const productCode = getProductCodeByName(
      product,
      this.props.configuration?.products,
    );

    const payload = {
      membershipType,
      productCode,
    };

    await this.props.updateQuote(payload);
  };

  handleRemoveMembership = () => {
    const { quote } = this.props;
    localStorage.quoteId = (quote?.quoteId || quote?.bookingReference) ?? '';
    this.handleUpdateMembership(
      getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember),
    );
    this.handleCloseDialog();
  }

  handleRemoveMembershipClick = () => {
    const { configuration, user, quote } = this.props;
    const dialogType = getRemoveDialogType(user, quote, configuration);
    const membershipCost = configuration?.membershipTypes?.directDebit?.costSingle;

    if (dialogType) {
      const isOverseas =
        dialogType === DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_OVERSEAS ||
        quote?.crossingBookings?.length;
      this.setState({
        membershipDialog: {
          type: dialogType,
          primaryAction: this.handleCloseDialog,
          secondaryAction: this.handleRemoveMembership,
          showSecondaryButton: !isOverseas,
          handleLogin: this.handleLogin,
          amount: isOverseas ? membershipCost : quote?.nonMemberSupplementsCost,
        },
      });
    } else {
      this.handleRemoveMembership();
    }
  }

  handleCheckUserAddress = async () => {
    const { router, refetchUser } = this.props;
    const { data } = await refetchUser();
    if (!data.user?.hasAddress) {
      this.handleUserMissingAddress();
    } else {
      router.push({
        pathname: routes.checkout,
      });
    }
  }

  handleUserPendingAddress = () => {
    const { AddAddressUrl } = getConfig().publicRuntimeConfig.ENVIRONMENT;
    window.open(AddAddressUrl, '_blank');
    this.setState({
      membershipDialog: {
        type: DIALOG_MEMBERSHIP_TYPE.MEMBER_ADDRESS_MISSING_PENDING,
        primaryAction: this.handleCheckUserAddress,
        onClose: this.handleCloseDialog,
      },
    });
  }

  handleUserMissingAddress = () => {
    this.setState({
      membershipDialog: {
        type: DIALOG_MEMBERSHIP_TYPE.MEMBER_ADDRESS_MISSING,
        primaryAction: this.handleUserPendingAddress,
        onClose: this.handleCloseDialog,
      },
    });
  }

  // component - pitch/booking component
  // amendType - if not booking, go directly to checkout page after creating amendment quote
  handleEditClick = async (component, amendType) => {
    const { createAmendQuote, bcode, quote } = this.props;
    const { amendmentRestrictions } = quote ?? {};
    const amendRestriction =
      getAmendRestrictionReason(amendmentRestrictions, component.amendmentRestrictions);
    if (amendRestriction) {
      this.setState({ amendRestriction });
      return;
    }

    const {
      bookingDates: { fromDate, toDate },
      campsite: { id: campsiteId, siteCode, type },
      id: componentId,
      eventType,
    } = component;

    const isAmendJourney = !!this.props.booking;

    if (moment().isAfter(moment(fromDate)) && isAmendJourney) {
      this.setState({ amendRestriction: AMEND_RESTRICTIONS.PITCH_STARTING_SOON });
      return;
    }

    if (this.props.isBookingBasket) {
      this.setState({ amendLoading: true, amendType: amendType || AMEND_TYPES.BOOKING });
      await createAmendQuote({ bookingReference: bcode });
      this.setState({ amendLoading: false, amendType: '' });
      if (amendType) {
        window.localStorage.amendIntent = '';
        this.props.router.push({
          pathname: routes.checkout,
          query: {
            amendType,
          },
        });
      } else {
        window.localStorage.amendIntent = componentId;
        this.props.router.push({
          pathname: routes.sites,
          query: {
            campsiteId,
            end: format(toDate, DATE_FORMAT_DEFAULT),
            start: format(fromDate, DATE_FORMAT_DEFAULT),
            siteCode,
            bookingId: bcode,
            isOverseas: type === ids.OVERSEAS_SITE,
            componentId,
            eventType,
          },
        });
      }
    } else {
      window.localStorage.amendIntent = componentId;
      updateRouterQuery(routes.sites, {
        campsiteId,
        componentId,
        end: format(toDate, DATE_FORMAT_DEFAULT),
        start: format(fromDate, DATE_FORMAT_DEFAULT),
        siteCode,
        isOverseas: type === ids.OVERSEAS_SITE,
      });

      this.props.handleDrawerToggle();
    }
  }

  handleAmendParty = (componentToAmend) => {
    if (!this.canAmendGuard(componentToAmend)) return;
    this.setState({
      componentToAmend,
    });
  }

  handleAddOsnvClick = async (component) => {
    if (!component) {
      return;
    }
    const { createAmendQuote, bcode, quote } = this.props;
    const { amendmentRestrictions } = quote ?? {};
    const amendRestriction =
      getAmendRestrictionReason(
        amendmentRestrictions, component.amendmentRestrictions, false, true,
      );
    if (amendRestriction) {
      this.setState({ amendRestriction });
      return;
    }

    if (this.props.isBookingBasket) {
      this.setState({ addOsnvLoading: true });
      await createAmendQuote({ bookingReference: bcode });
      this.handleOpenOsnvPopUp();
      this.setState({ addOsnvLoading: false });
    } else {
      this.handleOpenOsnvPopUp();
    }
  }

  handleViewClick = (campsiteId, campsiteName) => {
    updateRouterQuery(routes.sites, {
      activePin: campsiteId,
      campsiteId,
      campsiteName,
    });

    this.props.handleDrawerToggle();
  }

  openPopUp = (data) => {
    const { client } = this.props;
    client.writeData({ data });
  }

  openDepositComponentSummaryPopUp = () => {
    this.openPopUp({
      depositComponentSummaryPopUp: {
        __typename: 'DepositComponentSummaryPopUp',
        open: true,
        components: this.getQuoteDepositComponents(),
      },
    });
  }

  openRemovePackagePopUp = ({ id }) => {
    this.openPopUp({
      removePackagePopUp: {
        __typename: 'RemovePackagePopUp',
        open: true,
        componentId: id,
      },
    });
  }

  openRemoveVouchersPopUp = (componentId) => {
    this.openPopUp({
      removeVouchersPopUp: {
        __typename: 'RemoveVouchersPopUp',
        open: true,
        componentId,
      },
    });
  }

  openCancelSiteBookingPopUp = (refund, title) => {
    this.openPopUp({
      cancelSiteBookingPopUp: {
        __typename: 'CancelSiteBookingPopUp',
        open: true,
        refund,
        title,
      },
    });
  }

  openUnCancellablePopUp = () => {
    this.openPopUp({
      richTextPopUp: {
        __typename: 'RichTextPopUp',
        open: true,
        text: getDictionaryItem(this.props.client, 'MyBooking__UnCancellable__Text'),
      },
    });
  }

  toggleMembershipRemovePopUp = (value) => {
    this.setState({
      removeMembership: value ?? !this.state.removeMembership,
    });
  }

  openMembershipRemovePopUp = () => {
    this.toggleMembershipRemovePopUp(true);
  }

  toggleMoreInformationPopUp = () => {
    this.setState({
      isInformationPopUpOpen: !this.state.isInformationPopUpOpen,
    });
  }

  quoteWithoutComponent = ({ id: componentId, __typename: type }) => {
    const {
      quote: {
        campsiteBookings = [],
        crossingBookings = [],
        extras = [],
      } = {},
    } = this.props;
    const isPitch = type === 'Pitch' || type === 'BookingPitch';
    return {
      campsiteBookings: isPitch ?
        campsiteBookings.filter(({ id }) => id !== componentId) : campsiteBookings,
      crossingBookings: type === 'CrossingBookings' ? crossingBookings.filter(({ id }) => id !== componentId) : crossingBookings,
      extras: type === 'Extra' ? extras.filter(({ id }) => id !== componentId) : extras,
    };
  }

  wouldInvalidateItx = (component) => {
    const {
      configuration: {
        itxPackageRules,
        siteNightVoucherInfo: {
          extraName,
        },
      },
    } = this.props;

    const {
      campsiteBookings,
      crossingBookings,
      extras,
    } = this.quoteWithoutComponent(component);

    const stillApplicable = isITXApplicable(
      crossingBookings.some(booking => booking.inboundItinerary),
      sumAllNights(campsiteBookings),
      sumAllSNV(extras, extraName),
      itxPackageRules.qtyOvernightStaysRequired,
      itxPackageRules.qtyOverseasSiteNightVouchersRequired,
    );

    const hasITXCrossings = !!crossingBookings
      .find(booking => (
        booking.inboundItinerary
        && booking.fareType === 1 // 1 being ITX
      ));

    return !(stillApplicable && hasITXCrossings);
  }

  wouldInvalidateVouchers = (component) => {
    const {
      campsiteBookings,
      crossingBookings,
    } = this.quoteWithoutComponent(component);

    return !campsiteBookings.length && !crossingBookings.length;
  }

  getChangeTypeName = (changeType) => {
    const changeTypeData = this.props.configuration?.quoteChangeTypes.find(
      o => o.key === changeType,
    );

    return changeTypeData ? changeTypeData.value : QuoteChangeTypes.NONE;
  }

  checkToDisableCheckoutButton = (quote) => {
    const empty = !quote.crossingBookings.length
      && !quote.campsiteBookings.length
      && !quote.extras.length;

    if (empty) return true;

    if (quote.crossingBookings.length) {
      return quote.crossingBookings.some((crossing) => {
        const changeTypeName = this.getChangeTypeName(crossing.changeType);
        return (
          changeTypeName === QuoteChangeTypes.NOT_AVAILABLE
          || changeTypeName === QuoteChangeTypes.PRICE_DECREASE
        );
      });
    }

    if (quote.campsiteBookings?.some(
      booking => booking.pitches.some(
        pitch => !!pitch.preventBookingComponents?.length,
      ),
    )) {
      return true;
    }

    return false;
  }

  handleRenderingAcceptBtn = (changeType) => {
    const changeTypeName = this.getChangeTypeName(changeType);

    let showAcceptButton = false;

    switch (changeTypeName) {
      case QuoteChangeTypes.NOT_AVAILABLE:
        showAcceptButton = false;
        break;
      case QuoteChangeTypes.ITX_CHEAPER:
        showAcceptButton = false;
        break;
      case QuoteChangeTypes.PRICE_DECREASE:
        showAcceptButton = true;
        break;
      case QuoteChangeTypes.PRICE_INCREASE:
        showAcceptButton = true;
        break;
      case QuoteChangeTypes.RETAIL_CHEAPER:
        showAcceptButton = false;
        break;
      default:
        showAcceptButton = false;
    }

    if (showAcceptButton) {
      return (
        <AcceptButton />
      );
    }

    return null;
  }

  handleUpSellViewPortClick = () => {
    const {
      configuration: {
        defaultCampsiteZoomLevel,
        defaultCrossingZoomLevel,
        ports,
      },
      quote,
    } = this.props;
    let port;

    if (quote && quote.crossingBookings.length) {
      const [crossing] = quote.crossingBookings;
      const { arrivalPort } = crossing.outboundItinerary;

      port = ports.find(
        p => (p.portCodes.length && p.portCodes.indexOf(arrivalPort) > -1),
      );
    }

    updateRouterQuery(routes.sites, {
      activePin: null,
      ...port && ({
        poi_lat: port.lat,
        poi_lng: port.lon,
        showResults: true,
        zoomLevel: defaultCrossingZoomLevel || defaultCampsiteZoomLevel,
      }),
    });

    this.props.toggleBasket();
    this.props.handleDrawerToggle();
  }

  handleOpenOsnvPopUp = () => {
    const { client, quote, booking } = this.props;
    let bookingObject = quote;
    if (booking) {
      // use original OSNV quantity as minimum
      bookingObject = booking;
    }
    const quantity = bookingObject?.extras?.find(
      (extra) => extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER,
    )?.quantity;

    client.writeData({
      data: {
        siteNightVoucherPopUp: {
          open: true,
          minimum: quantity || 0,
          __typename: 'SiteNightVoucherPopUp',
        },
      },
    });
  }

  handleUpSellViewOnsvClick = () => {
    const { toggleBasket } = this.props;

    toggleBasket();
    this.handleOpenOsnvPopUp();
  }

  handlePackageUpgrade = () => null

  handleUserAttemptedCheckout = () => {
    this.setState({
      hasUserAttemptedCheckout: true,
    }, () => {
      const flatPitchBookingsDates = this.getPitchBookings(this.props.quote.campsiteBookings);
      const basketScopedOverlappingCampsiteBookings
        = this.checkAnyCampsiteOverlap(flatPitchBookingsDates);
      const showCampsiteOverlapPassivePromptForPrevent
        = this.checkShowCampsiteOverlapPassivePromptForPrevent(
          basketScopedOverlappingCampsiteBookings,
        );

      // we want to persist the validation state so if the user navigates around the site
      // and comes back to the basket then the validation persists until it is resolved.
      if (showCampsiteOverlapPassivePromptForPrevent) {
        this.props.client.writeData({
          data: {
            persistBasketValidation: true,
          },
        });
      }
    });
  }

  getPitchBookings = (campsiteBookings) => {
    const pitchBookings = [];

    campsiteBookings.forEach(({ pitches }) => (
      pitches.forEach(({ bookingDates, id, parentPitchId }) => {
        pitchBookings.push({
          fromDate: bookingDates.fromDate,
          toDate: bookingDates.toDate,
          pitchId: id,
          parentPitchId,
        });
      })
    ));

    return pitchBookings;
  }

  checkAnyCampsiteOverlap = (pitchBookings) => (
    pitchBookings.some((pitchBooking) => this.checkCampsiteOverlap(
      pitchBooking, pitchBookings,
    ))
  );

  // Ignore overlap if pitches are parent-child
  arePitchRelated = (pitch1, pitch2) => (
    pitch1.parentPitchId === Number(pitch2.pitchId) ||
    pitch2.parentPitchId === Number(pitch1.pitchId) ||
    Number(pitch1.pitchId) === pitch2.parentPitchId ||
    Number(pitch2.pitchId) === pitch1.parentPitchId
  );

  checkCampsiteOverlap = (selectedPitchBooking, pitchBookings) => {
    let pitchOverlaps = false;
    pitchBookings.forEach((pitchBooking) => {
      // check for overlap
      if (
        selectedPitchBooking.pitchId !== pitchBooking.pitchId
        && !this.arePitchRelated(selectedPitchBooking, pitchBooking)
        && !selectedPitchBooking.parentPitchId
        && (areRangesOverlapping(
          selectedPitchBooking.fromDate,
          selectedPitchBooking.toDate,
          pitchBooking.fromDate,
          pitchBooking.toDate,
        ) || (
          selectedPitchBooking.fromDate === pitchBooking.fromDate
          && selectedPitchBooking.toDate === pitchBooking.toDate
        ))
      ) {
        pitchOverlaps = true;
      }
    });

    return pitchOverlaps;
  };

  checkAnyCrossingOverlap = (crossingBookings) => {
    const dateRanges = [];

    if (crossingBookings.length > 1) {
      crossingBookings.forEach(({ inboundItinerary, outboundItinerary }) => {
        dateRanges.push(outboundItinerary.crossingDateTime);

        if (inboundItinerary) {
          dateRanges.push(inboundItinerary.crossingDateTime);
        } else {
          dateRanges.push(outboundItinerary.crossingDateTime);
        }
      });

      return areRangesOverlapping(...dateRanges);
    }

    return false;
  }

  checkShowCampsiteOverlapPassivePromptForPrevent = (basketScopedOverlappingCampsiteBookings) => (
    this.state.basketScopedOverlappingSitesType === BasketScopedOverlappingSitesType.PREVENT
    && basketScopedOverlappingCampsiteBookings
    && this.state.hasUserAttemptedCheckout
  );

  getQuoteDepositComponents = () => {
    const components = [];

    this.props.quote.campsiteBookings.forEach(campsiteBooking => {
      campsiteBooking.pitches.forEach(pitch => {
        components.push({
          __typename: 'DepositComponent',
          deposit: pitch.depositAmount,
          name: `${campsiteBooking.campsite.name} - ${pitch.type}`,
        });
      });
    });

    this.props.quote.crossingBookings.forEach(crossingBooking => {
      const { supplierCode } = crossingBooking;
      const { arrivalPort, departurePort } = crossingBooking.outboundItinerary;

      const route = this.props.configuration.routes.find(r => (
        r.supplierCode === supplierCode
        && r.arrivalPort === arrivalPort
        && r.departurePort === departurePort
      ));

      components.push({
        __typename: 'DepositComponent',
        deposit: crossingBooking.depositAmount || crossingBooking.totalPrice,
        name: route.description,
      });
    });

    this.props.quote.extras.forEach(extra => {
      components.push({
        __typename: 'DepositComponent',
        deposit: extra.depositAmount || extra.totalPrice,
        name: extra.description,
      });
    });

    return components;
  }

  handleContinueAmend = () => {
    if (this.state.amendInProgress.campsiteId) {
      this.setState({ amendInProgress: null });
      this.props.router.push({
        pathname: routes.sites,
        query: { ...this.state.amendInProgress },
      });
    } else {
      this.setState({ amendInProgress: null });
      this.props.router.push({
        pathname: routes.checkout,
      });
    }
  }

  handleCancelAmend = () => {
    this.props.resetSession();
    this.setState({
      amendInProgress: null,
    });
  }

  render() {
    if (this.props.userLoading || this.props.loading) {
      return <LoadingSpinner />;
    }

    const {
      configuration: {
        defaultCampsiteLogoImage,
        partyMemberTypes,
        suppliers,
        ports,
        itxPackageRules,
        siteNightVoucherInfo: {
          extraName,
        },
        membershipTypes,
      },
      toggleBasket,
      bcode,
      totalValue,
      outstandingBalance,
      isBookingBasket,
      isOverseasBooking,
      isUpcoming,
      partyDetails,
      handlePayBalance,
      quote,
    } = this.props;
    const loading = this.props.loading || this.props.quoteLoading;
    const error = this.props.error || this.props.quoteError;
    const membershipCost = membershipTypes?.directDebit?.costSingle;
    const isMembershipByDDSubmitted =
      quote?.membershipType === getENumWithValue(quoteMembershipTypes.MembershipByDD) &&
      quote?.membership?.membershipId;
    let { crossingBookings } = quote;
    if (!crossingBookings) {
      crossingBookings = [];
    }

    let isItx = false;
    let showUpSell = false;
    let showPackageUpgrade = false;
    let packageUpgradeSaving = 0;

    if (crossingBookings.length) {
      isItx =
        isITXApplicable(
          crossingBookings.some(booking => booking.inboundItinerary),
          sumAllNights(quote.campsiteBookings),
          sumAllSNV(quote.extras, extraName),
          itxPackageRules.qtyOvernightStaysRequired,
          itxPackageRules.qtyOverseasSiteNightVouchersRequired,
        );

      // It maybe itx applicable but if there is no ITX match in the basket then it is not
      // true itx package
      isItx = !!crossingBookings
        .find(booking => (
          isItx
          && booking.inboundItinerary
          && booking.fareType === 1 // 1 being ITX
        ));

      showUpSell = ItxProvider.checkToShowUpsell(quote);

      showPackageUpgrade = ItxProvider.checkToShowPackageUpgrade(
        quote,
        ItxProvider.getMaxNightsFromQuote(quote),
      );

      packageUpgradeSaving = ItxProvider.getItxSaving(quote);
    }

    const campsiteBookings = [];
    const advancedCampsiteBookings = {};

    quote.campsiteBookings.forEach(campsite => campsite && campsite.pitches.forEach((p) => {
      const { pitch, ...otherCampsiteProps } = campsite;
      const campsiteProps = { ...otherCampsiteProps, ...p, partyMembers: quote.partyMembers };
      const parentPitchId = p?.parentPitchId;
      if (!parentPitchId) {
        campsiteBookings.push(campsiteProps);
      } else if (advancedCampsiteBookings[parentPitchId]) {
        advancedCampsiteBookings[parentPitchId].push(
          campsiteProps,
        );
      } else {
        advancedCampsiteBookings[parentPitchId] = [
          campsiteProps,
        ];
      }
    }));

    const bookingComponents = [
      ...campsiteBookings,
      ...crossingBookings,
    ];

    const flatPitchBookingsDates = this.getPitchBookings(quote.campsiteBookings);
    const basketScopedOverlappingCampsiteBookings
      = this.checkAnyCampsiteOverlap(flatPitchBookingsDates);
    const overlappingCrossingBookings = this.checkAnyCrossingOverlap(crossingBookings);
    const showCampsiteOverlapPassivePromptForPrevent
      = this.checkShowCampsiteOverlapPassivePromptForPrevent(
        basketScopedOverlappingCampsiteBookings,
      );

    const priceDecreaseItemIds = crossingBookings
      // filter only price decrease
      .filter(crossing => crossing.changeType === 2)
      // return only ids
      .map(({ id }) => id);

    // Sort bookings by departure dates
    bookingComponents.sort(sortBookings);

    // Insert all advanced booking after parent pitch
    Object.keys(advancedCampsiteBookings).forEach((parentId, i) => {
      advancedCampsiteBookings[parentId].forEach(
        (advancedCampsiteBooking, advancedBookingIndex) => {
          const index = bookingComponents.findIndex((booking) => booking.id === `${parentId}`);
          bookingComponents.splice(index + advancedBookingIndex + 1, 0, {
            ...advancedCampsiteBooking,
            index: advancedBookingIndex + 1,
          });
        },
      );
    });

    const memberSaving = (
      !quote.totalPriceIncludesNonMemberSupplements
      && quote.nonMemberSupplementsCost > 0
    ) ? quote.nonMemberSupplementsCost : 0;

    const showMembershipLineItem = isMembershipShownInBasket(quote);

    const totalPrice = (quote.totalPrice ?? 0) + (showMembershipLineItem ? membershipCost : 0);

    const { membershipDialog } = this.state;
    const dialogMembershipProps =
      getMembershipDialogProps({ dialogType: membershipDialog?.type });

    const isLastItemInBasketACampsite
    = campsiteBookings.length === 1 && !crossingBookings.length && !quote.extras.length;

    const isLastItem =
      (campsiteBookings.length + crossingBookings.length + quote.extras.length) < 1;

    return (
      <Fragment>
        <MembershipDialog
          open={!!membershipDialog}
          loading={this.props.loading}
          membershipCost={membershipCost}
          {...dialogMembershipProps}
          {...membershipDialog}
        />
        {!!this.state.amendRestriction &&
        <NoticeModal
          open={!!this.state.amendRestriction}
          onClose={() => this.setState({ amendRestriction: 0 })}
          titleDictionary={dictionaryItem(
            'Amend',
            'NotAllowed',
          )}
        >
          <Text
            align="center"
            dictionary={dictionaryItem(
              'Amend',
              'NotAllowed',
              AMEND_KEYS[this.state.amendRestriction] || '',
              'Description',
            ).replace('____', '__')} // If dictionary key is blank
          />
          <Text dictionary={dictionaryItem('CamcCallCentrePhoneNumber')} weight="bold" marginBottom="2rem" />
          <RichText
            dictionary={dictionaryItem(
              'Amend',
              'NotAllowed',
            )}
            flexList
          />
        </NoticeModal>}
        {this.state.componentToAmend &&
        <NoticeModal
          {...getModalProps(AMEND_MODALS.AMEND_PARTY)}
          open={!!this.state.componentToAmend}
          onClose={() => this.setState({ componentToAmend: null })}
          onSecondaryAction={() => {
            this.handleEditClick(
              this.state.componentToAmend, AMEND_TYPES.PARTY,
            );
            this.setState({
              componentToAmend: null,
            });
          }}
          onPrimaryAction={() => {
            this.setState({
              componentToAmend: null,
            });
          }}
        />}
        {this.state.cancelPopUp &&
          <NoticeModal
            {...getModalProps(AMEND_MODALS.AMEND_CANCEL_AMENDING)}
            open={!!this.state.cancelPopUp}
            onClose={() => this.setState({ cancelPopUp: false })}
            onPrimaryAction={() => {
              this.setState({
                cancelPopUp: false,
              });
            }}
          />}
        {!!this.state.amendInProgress &&
          <NoticeModal
            open={!!this.state.amendInProgress}
            onClose={() => this.setState({ amendInProgress: null })}
            titleText={getDictionaryItem(
              this.props.client,
              'Amend__InProgress__Title',
            ).replace('{{BookingReference}}', this.state.amendInProgress.bookingId)}
            descriptionDictionary={dictionaryItem(
              'Amend',
              'InProgress',
              'Description',
            )}
            onPrimaryAction={this.handleContinueAmend}
            onSecondaryAction={this.handleCancelAmend}
            primaryActionDictionary={dictionaryItem('Amend', 'InProgress', 'Continue')}
            secondaryActionDictionary={dictionaryItem('Amend', 'InProgress', 'Cancel')}
          />}
        {this.state.removeMembership &&
          <NoticeModal
            {...getModalProps(AMEND_MODALS.AMEND_REMOVE_MEMBERSHIP)}
            open={this.state.removeMembership}
            onClose={() => this.toggleMembershipRemovePopUp(false)}
            onPrimaryAction={() => this.toggleMembershipRemovePopUp(false)}
          />}
        <NoticeModal
          open={this.state.isInformationPopUpOpen}
          titleDictionary={dictionaryItem('ImportantInfoPopUp')}
          secondaryActionDictionary={dictionaryItem('ImportantInfoPopUp', 'Close')}
          onSecondaryAction={this.toggleMoreInformationPopUp}
          onClose={this.toggleMoreInformationPopUp}
        >
          <BasketImportantInformation
            importantInformation={this.props.quote?.importantInformation}
          />
        </NoticeModal>
        {isBookingBasket ? (
          <BookingBasketHeader
            bookingRef={bcode}
            isUpcoming={isUpcoming}
            onMoreInformationClick={this.toggleMoreInformationPopUp}
            showImportantInformation={!!this.props.quote?.importantInformation?.length}
          />
        ) : (
          <BasketHeader
            handleClose={this.props.toggleBasket}
            handleFullClick={this.props.handleFullClick}
            isFull={this.props.isFull}
            quoteId={quote.quoteId}
            onCloseHoldInventoryPopup={this.handleCloseDialog}
            isAmend={!!quote?.isBookingAmendmentQuote}
          />
        )}
        <Container>
          {error &&
            <>
              <h3>{error.message}</h3>
              {error.networkError &&
                <small>{error.networkError.result}</small>
              }
            </>
          }
          {loading &&
            <BasketLoader />
          }

          {!loading &&
            !!quote.partyMembers &&
            (!!crossingBookings.length ||
            !!quote.campsiteBookings.length) &&
              <BasketPartyMembers
                isBookingBasket={isBookingBasket}
                isUpcomingBookingBasket={isUpcoming}
                partyMembers={quote.partyMembers}
                partyMemberTypes={partyMemberTypes}
                partyDetails={partyDetails}
                onAmendParty={() => this.handleAmendParty(
                bookingComponents?.[0],
                )}
                onAmendOutfit={() => this.handleEditClick(
                bookingComponents?.[0], AMEND_TYPES.OUTFIT,
                )}
                amendLoading={this.state.amendLoading &&
                  this.state.amendType !== AMEND_TYPES.BOOKING}
              />
          }

          {!loading && bookingComponents.map(component => (
            // eslint-disable-next-line no-underscore-dangle
            (component.__typename === 'Pitch' || component.__typename === 'BookingPitch') ? (
              <BasketItem
                amendLoading={this.state.amendLoading &&
                  this.state.amendType === AMEND_TYPES.BOOKING}
                basePrice={component.basePrice}
                bigBasket={this.props.isFull}
                isAmend={!!quote?.isBookingAmendmentQuote}
                isCampsite
                componentId={component.id}
                campsite={component.campsite}
                defaultCampsiteLogoImage={defaultCampsiteLogoImage}
                handleDrawerToggle={this.props.toggleBasket}
                hasUserAttemptedCheckout={this.state.hasUserAttemptedCheckout}
                isItx={!!isItx}
                isPreviousBookingBasket={isBookingBasket && !isUpcoming}
                isBookingBasket={isBookingBasket}
                key={component.id}
                numNights={component.numNights}
                onCancelClick={() => this.props.handleCancel(
                  bcode, component,
                )}
                onEditClick={() => this.handleEditClick(component)}
                onViewClick={
                  () => this.handleViewClick(
                    component.campsite.id, component.campsite.name,
                  )
                }
                onCancelAmending={() => this.setState({ cancelPopUp: true })}
                onRemoveResetSession={
                  isLastItemInBasketACampsite ? this.props.resetSession : undefined
                }
                quoteChangeTypes={this.props.configuration?.quoteChangeTypes}
                openRemovePackagePopUp={() => this.openRemovePackagePopUp(component)}
                openRemoveVouchersPopUp={() => this.openRemoveVouchersPopUp(component.id)}
                openCancelSiteBookingPopUp={(refund) => (
                  this.openCancelSiteBookingPopUp(refund, component.campsite.name)
                )}
                basketScopedOverlappingSitesType={this.state.basketScopedOverlappingSitesType}
                totalPrice={component.totalPrice}
                wouldInvalidateItx={isItx ? this.wouldInvalidateItx(component) : false}
                wouldInvalidateVouchers={
                  quote.extras.length ? this.wouldInvalidateVouchers(component) : false
                }
                wouldBasketScopedOverlap={this.checkCampsiteOverlap(
                  {
                    pitchId: component.id,
                    parentPitchId: component.parentPitchId,
                    ...component.bookingDates,
                  }, flatPitchBookingsDates,
                )}
                advancedPitch={!!component.parentPitchId}
                partyMemberTypes={partyMemberTypes}
                partyMembers={component.partyMembers}
                parentPitchId={component.parentPitchId}
                partyMemberReference={component.partyMemberReference}
                preBookingCheckIssueTypes={this.props.configuration?.preBookingCheckIssueTypes}
                preventBookingComponents={component.preventBookingComponents}
                index={component.index}
                warnBookingComponents={component.warnBookingComponents}
              >
                <BasketItemCampsite
                  bigBasket={this.props.isFull}
                  campsite={component.campsite}
                  specialRequests={component.specialRequests}
                  campsiteId={component.campsite.id}
                  dates={component.bookingDates}
                  isItx={isItx}
                  key={component.id}
                  quoteId={quote.quoteId}
                  partyMemberTypes={partyMemberTypes}
                  isBookingBasket={isBookingBasket}
                  {...component}
                />
              </BasketItem>
            ) : (
              <BasketItem
                basePrice={component.basePrice}
                componentId={component.id}
                logo={returnSupplierLogo(suppliers, component.supplierCode)}
                defaultCampsiteLogoImage={defaultCampsiteLogoImage}
                isItx={isItx}
                isPreviousBookingBasket={isBookingBasket && !isUpcoming}
                isBookingBasket={isBookingBasket}
                key={component.id}
                totalPrice={component.totalPrice}
                changeType={component.changeType}
                changePrice={component.changePrice}
                changeMessage={component.changeMessage}
                quoteChangeTypes={this.props.configuration?.quoteChangeTypes}
                acceptButton={this.handleRenderingAcceptBtn(component.changeType)}
                onCancelClick={() => this.props.handleCancel(
                  bcode, component,
                )}
                openRemovePackagePopUp={() => this.openRemovePackagePopUp(component)}
                openRemoveVouchersPopUp={() => this.openRemoveVouchersPopUp(component.id)}
                wouldInvalidateItx={isItx ? this.wouldInvalidateItx(component) : false}
                wouldInvalidateVouchers={
                  quote.extras.length ? this.wouldInvalidateVouchers(component) : false
                }
              >
                <BasketItemFerry
                  bigBasket={this.props.isFull}
                  isItx={isItx}
                  ports={ports}
                  suppliers={suppliers}
                  {...component}
                />
              </BasketItem>
            )
          ))}

          {(showMembershipLineItem && !loading) &&
            <MiniBasketItem>
              <BasketMembership
                totalPrice={membershipCost}
                handleRemoveMembership={this.handleRemoveMembershipClick}
              />
            </MiniBasketItem>
          }

          {!loading && quote.extras.map(extra => (
            <MiniBasketItem key={extra.id}>
              <BasketExtra
                isItx={isItx}
                isPreviousBookingBasket={isBookingBasket && !isUpcoming}
                isBookingBasket={isBookingBasket}
                {...extra}
                openMembershipRemovePopUp={isMembershipByDDSubmitted ?
                  this.openMembershipRemovePopUp : undefined}
                openRemovePackagePopUp={isBookingBasket ?
                  () => this.openUnCancellablePopUp()
                  : () => this.openRemovePackagePopUp(extra)
                } // @TODO logic for un cancellable item
                wouldInvalidateItx={isItx ? this.wouldInvalidateItx(extra) : false}
                onAddOSNV={() => this.handleAddOsnvClick(bookingComponents?.[0])}
                amendLoading={this.state.addOsnvLoading}
                isAmend={!!quote?.isBookingAmendmentQuote}
                configuration={this.props.configuration}
                resetSession={this.props.resetSession}
                shouldResetSession={isLastItem}
              />
            </MiniBasketItem>
          ))}
        </Container>

        {showCampsiteOverlapPassivePromptForPrevent &&
          <BasketOverlappingSiteDatesPassivePrompt />
        }

        {/* (sometimes called ITX passive prompt) */}
        {(showCampsiteOverlapPassivePromptForPrevent && showUpSell) &&
          <BasketUpSell
            onViewPortClick={() => this.handleUpSellViewPortClick()}
            onViewOnsvClick={() => this.handleUpSellViewOnsvClick()}
          />
        }

        {showPackageUpgrade &&
          <BasketPackageUpgrade
            componentIds={crossingBookings.map(c => c.id)}
            onPackageUpgradeClick={() => this.handlePackageUpgrade()}
            saving={packageUpgradeSaving}
          />
        }

        {this.props.isBookingBasket ? (
          <BookingBasketSummary
            loading={loading}
            isOverseas={isOverseasBooking}
            isUpcoming={isUpcoming}
            remainingPrice={outstandingBalance}
            totalPrice={totalValue}
            onPayBalance={handlePayBalance ? () => handlePayBalance?.(quote) : undefined}
            onAddOSNV={() => this.handleAddOsnvClick(bookingComponents?.[0])}
            amendLoading={this.state.addOsnvLoading}
          />
        ) : (
          <BasketSummary
            quote={quote}
            toggleBasket={toggleBasket}
            deposit={quote.deposit}
            isItx={isItx}
            isFull={this.props.isFull}
            quoteId={quote.quoteId}
            totalPrice={totalPrice}
            basketScopedOverlappingBookings={
              basketScopedOverlappingCampsiteBookings || overlappingCrossingBookings
            }
            memberSaving={memberSaving}
            priceDecreaseItemIds={priceDecreaseItemIds}
            // disable button if crossing.changeType is equal to Unavailable or Price Increase.
            extrasOnly={(
              !!quote.extras.length
              && !crossingBookings.length
              && !quote.campsiteBookings.length
            )}
            disableCheckoutBtn={
              this.checkToDisableCheckoutButton(quote)
              || showCampsiteOverlapPassivePromptForPrevent
            }
            handleUserAttemptedCheckout={this.handleUserAttemptedCheckout}
            handleUserMissingAddress={this.handleUserMissingAddress}
            basketScopedOverlappingSitesType={this.state.basketScopedOverlappingSitesType}
            openDepositComponentSummaryPopUp={this.openDepositComponentSummaryPopUp}
            booking={quote.isBookingAmendmentQuote ? this.props.booking : null}
            client={this.props.client}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withTheme,
  withRouter,
  graphql(GET_PERSIST_BASKET_VALIDATION, {
    props: ({ data }) => ({
      persistBasketValidation: data.persistBasketValidation,
    }),
  }),
  graphql(GET_USER, {
    options: {
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    },
    props: ({ data }) => ({
      user: data.user,
      userLoading: data.loading,
      userError: data.error,
      refetchUser: data.refetch,
    }),
    skip: props => !isLoggedIn(),
  }),
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      loading: data.loading,
    }),
  }),
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(CREATE_AMEND_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      createAmendQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  withStateMutation({ name: 'updateQuote' }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(BasketContent);
