import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledDrawerHandle, { Handle } from './DrawerHandle.style';

const defaultProps = {
  handleDrawerToggle() {},
  open: false,
  orientation: 'vertical',
  size: '3rem',
};

const propTypes = {
  children: PropTypes.node.isRequired,
  handleDrawerToggle: PropTypes.func,
  handleLabel: PropTypes.string.isRequired,
  open: PropTypes.bool,
  orientation: PropTypes.string,
  size: PropTypes.string,
};

function DrawerHandle(props) {
  return (
    <StyledDrawerHandle
      orientation={props.orientation}
      size={props.size}
    >
      <Handle
        onClick={() => props.handleDrawerToggle(!props.open)}
        aria-label={props.handleLabel}
        role="button"
      >
        {React.Children.map(props.children, child => (
          React.cloneElement(child)
        ))}
      </Handle>
    </StyledDrawerHandle>
  );
}

DrawerHandle.defaultProps = defaultProps;
DrawerHandle.propTypes = propTypes;

export default memo(DrawerHandle);
