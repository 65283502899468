import React from 'react';
import PropTypes from 'prop-types';
import {
  AmendPartyNav,
  MiniBasketItem,
  Header,
  Title,
  ButtonLinkProminent,
} from './BasketPartyMembers.style';
import Text from '../ui/Text';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import { stringifyPartyMembers } from '../../lib/helpers/party';
import { dictionaryItem } from '../../hocs/withDictionary';

function BasketPartyMembers({
  partyMembers,
  partyMemberTypes,
  onAmendParty,
  onAmendOutfit,
  isBookingBasket,
  isUpcomingBookingBasket,
  onlyMainParty = true,
  amendLoading,
}) {
  const filteredPartyMembers = onlyMainParty ? partyMembers.filter(
    (partyMember) => !partyMember.comment,
  ) : partyMembers;
  const partyString = stringifyPartyMembers(filteredPartyMembers, partyMemberTypes);

  return (
    <MiniBasketItem>
      <Header>
        <div>
          <Title dictionary={dictionaryItem('BasketPartyMembers')} />
          <Text>
            {partyString}
          </Text>
        </div>
      </Header>
      {amendLoading && <LoadingSpinner size="1rem" />}
      {(isBookingBasket && isUpcomingBookingBasket) &&
        <AmendPartyNav>
          <>
            {onAmendParty &&
              <ButtonLinkProminent onClick={onAmendParty} dictionary={dictionaryItem('BasketItem__AmendParty')} />
            }
            {onAmendOutfit &&
              <ButtonLinkProminent onClick={onAmendOutfit} dictionary={dictionaryItem('BasketItem__AmendOutfit')} />
            }
          </>
        </AmendPartyNav>
      }
    </MiniBasketItem>
  );
}

BasketPartyMembers.propTypes = {
  partyMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  partyMemberTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAmendParty: PropTypes.func,
  onAmendOutfit: PropTypes.func,
  isBookingBasket: PropTypes.bool,
  isUpcomingBookingBasket: PropTypes.bool,
  onlyMainParty: PropTypes.bool,
  amendLoading: PropTypes.bool,
};

BasketPartyMembers.defaultProps = {
  onAmendParty: () => {},
  onAmendOutfit: () => {},
  isBookingBasket: false,
  isUpcomingBookingBasket: false,
  onlyMainParty: true,
  amendLoading: false,
};

export default BasketPartyMembers;
