import React from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../hocs/withDictionary';

import { ButtonBordered } from '../ui/Button';
import Modal, { ModalClose, ModalContent, ModalIcon } from '../ui/Modal';
import Title from '../ui/Title';
import Text from '../ui/Text';

import svgError from '../../static/images/icons/Error.svg';

import { Row, Col } from '../ui/Grid';

function DialogBookingOverlap({ open, ...props }) {
  return (
    <Modal active={open} overlay>
      <ModalContent size="small">
        <ModalClose onClick={props.onCancel} />
        <ModalIcon icon={svgError} />

        <Title
          align="center"
          dictionary={dictionaryItem(props.onContinue ? 'DialogBookingOverlap__Warning' : 'DialogBookingOverlap__Prevent')}
          tag={3}
          size={3}
          marginBottom
        />
        <Text
          align="center"
          dictionary={dictionaryItem(props.onContinue ? 'DialogBookingOverlap__Warning' : 'DialogBookingOverlap__Prevent')}
          marginBottom="2rem"
          size="1rem"
        />

        <Row>
          <Col>
            <ButtonBordered
              dictionary={dictionaryItem(props.onContinue ? 'DialogBookingOverlap__Warning' : 'DialogBookingOverlap__Prevent', 'Cancel')}
              onClick={props.onCancel}
              buttonStyle={{ width: '100%' }}
            />
          </Col>
          {props.onContinue &&
            <Col>
              <ButtonBordered
                dictionary={dictionaryItem('DialogBookingOverlap', 'Continue')}
                onClick={props.onContinue}
              />
            </Col>
          }
        </Row>
      </ModalContent>
    </Modal>
  );
}

DialogBookingOverlap.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  open: PropTypes.bool,
};

DialogBookingOverlap.defaultProps = {
  onCancel() { },
  onContinue: undefined,
  open: false,
};

export default DialogBookingOverlap;
