import styled, { css } from 'styled-components';

import BasketIcon from './BasketIcon';

import Drawer, { DrawerContent as DrawerContentComponent } from '../ui/Drawer';

import { above, below } from '../../styles/libs';
import detectiOS from '../../lib/detectiOS';

export default styled(Drawer)`
  ${({ full, theme }) => above(theme.TABLET)`
    width: ${full ? theme.ONE_PANEL : theme.TWO_PANEL};
  `};

  ${({ full, theme }) => above(theme.DESKTOP)`
    width: ${full ? theme.TWO_PANEL_WEIGHTED_RIGHT_2 : theme.TWO_PANEL_WEIGHTED_RIGHT_1};
  `};

  ${({ full, theme }) => above(theme.DESKTOP_LARGE)`
    width: ${full ? theme.TWO_PANEL : theme.TWO_PANEL_WEIGHTED_LEFT_2};
  `};

  position: absolute;
  top: 0;
  right: 0px;
  height: 100vh;
  pointer-events: auto;

  ${({ theme }) => below(theme.TABLET)`
    height: 100%;
  `}
`;

export const BasketWrapper = styled.div`
  overflow-x: hidden;
  position: ${({ isMobile }) => (isMobile ? 'fixed' : 'absolute')};
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: none;
  ${({ theme }) => below(theme.TABLET) && !!detectiOS() && css`
    overflow-y: none;
  `}

  ${({ isMobile }) => isMobile && css`
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    transition: opacity 0.5s;
    transition-timing-function: ${({ isVisible }) => (isVisible ? 'cubic-bezier(0, 1, 0, 1)' : 'cubic-bezier(1, 0, 1, 0)')};
  `}
`;

export const DrawerContent = styled(DrawerContentComponent)`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  position: sticky;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DrawerHandleBody = styled.div`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  width: 100%;
  height: 100%;
`;

export const BasketHandle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const BasketHandleIcon = styled(BasketIcon)`
  position: absolute;
  top: 0;
  margin-top: 1rem;
`;
