import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalClose } from '..';
import Title from '../../Title';

import {
  ModalContentStyled,
  Header,
  Footer,
  FooterAction,
  FooterActionSecondary,
} from './FormModal.style';

const FormModal = ({
  children,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  open,
  titleDictionary,
  titleText,
  primaryActionDictionary,
  secondaryActionDictionary,
  primaryActionDisabled,
  showPrimaryAction,
  showSecondaryAction,
}) => (
  <Modal
    active={open}
    overlay
  >
    <ModalContentStyled>
      {onClose && <ModalClose type="Button" onClick={onClose} />}
      <Header>
        <Title tag={3} size={2} dictionary={titleDictionary}>{titleText}</Title>
      </Header>
      {children}
      <Footer hasSecondaryAction={!!onSecondaryAction && showSecondaryAction}>
        {onSecondaryAction && showSecondaryAction &&
          <FooterActionSecondary size="small" type="button" onClick={onSecondaryAction} dictionary={secondaryActionDictionary} />
        }
        {onPrimaryAction && showPrimaryAction &&
          <FooterAction
            size="small"
            type="Button"
            onClick={onPrimaryAction}
            dictionary={primaryActionDictionary}
            disabled={primaryActionDisabled}
          />
        }
      </Footer>
    </ModalContentStyled>
  </Modal>
);

FormModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  primaryActionDictionary: PropTypes.string,
  primaryActionDisabled: PropTypes.bool,
  secondaryActionDictionary: PropTypes.string,
  open: PropTypes.bool,
  titleDictionary: PropTypes.string,
  titleText: PropTypes.string,
  showPrimaryAction: PropTypes.bool,
  showSecondaryAction: PropTypes.bool,
};

FormModal.defaultProps = {
  children: null,
  onClose: undefined,
  onPrimaryAction: undefined,
  onSecondaryAction: undefined,
  primaryActionDictionary: '',
  primaryActionDisabled: false,
  secondaryActionDictionary: '',
  open: false,
  titleDictionary: '',
  titleText: '',
  showPrimaryAction: true,
  showSecondaryAction: true,
};

export default FormModal;
