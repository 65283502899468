import React, { Fragment, useState } from 'react';
import Router from 'next/router';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import moment from 'moment';

import IbePropTypes from '../../IbePropTypes';

import formatPrice from '../../lib/formatPrice';
import { AMEND_MODALS, getAmendmentBreakdown, getModalProps } from '../../lib/helpers/amend';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import BasketScopedOverlappingSitesType from '../../config/basketScopedOverlappingSitesType';
import routes from '../../constants/routes';

import RESET_SESSION from '../Quote/graphql/resetSession';

import ButtonCheckout from './BasketCheckout';
import StyledBasketSummary, {
  Details,
  PriceTotal,
  TextMember,
  Section,
  SectionRow,
  SectionText,
  ButtonsContainer,
  AmendmentDiscardButton,
} from './BasketSummary.style';
import { ButtonInfo } from '../ui/Button';
import BasketBreakdown from './BasketBreakdown';
import NoticeModal from '../ui/Modal/NoticeModal/NoticeModal';

import svgInfo from '../../static/images/icons/Info.svg';

function BasketSummary(props) {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const amendBreakdown = getAmendmentBreakdown(props.quote);
  let quoteTotalPrice = props.quote.totalPrice;
  const isAmendment = !!props.quote?.isBookingAmendmentQuote;

  if (amendBreakdown && !quoteTotalPrice) {
    quoteTotalPrice = amendBreakdown.total;
  }

  const setModal = (state) => setIsDiscardModalOpen(state);

  const handleDiscard = async () => {
    // Reset the session to stop amending, then send user back to their bookings
    // with the current booking open
    await props.resetSession();
    Router.push({
      pathname: `${routes.myBookingsUpcoming}/${props.booking.bookingReference}`,
    });
  };

  const modalProps = getModalProps(AMEND_MODALS.AMEND_CANCEL);
  return (
    <Fragment>
      <StyledBasketSummary full={props.isFull}>
        <Details full={props.isFull}>
          {props.memberSaving > 0 && (
            <Section>
              <SectionRow>
                <SectionText>Sub total:</SectionText>
                <SectionText>
                  {formatPrice(props.totalPrice + props.memberSaving)}
                </SectionText>
              </SectionRow>
              <SectionRow>
                <TextMember>
                  <b>Member saving:</b>
                </TextMember>
                <TextMember>
                  <b>- {formatPrice(props.memberSaving)}</b>
                </TextMember>
              </SectionRow>
            </Section>
          )}
          <Section>
            {!!props.deposit &&
              moment(props.quote?.balanceDueDate).isAfter() && (
                <SectionRow>
                  <SectionText>
                    <SectionText
                      span
                      dictionary={dictionaryItem('BasketSummary', 'Deposit')}
                    />
                    <ButtonInfo
                      ariaLabel="Information"
                      icon={svgInfo}
                      marginLeft="0.5rem"
                      noPadding
                      onClick={props.openDepositComponentSummaryPopUp}
                      type="button"
                      buttonStyle={{ display: 'inline' }}
                    />
                  </SectionText>
                  <SectionText>{formatPrice(props.deposit)}</SectionText>
                </SectionRow>
            )}
            {isAmendment && (
              <>
                <BasketBreakdown
                  booking={props.booking}
                  quote={props.quote}
                  client={props.client}
                />
              </>
            )}
            <SectionRow>
              {!isAmendment ? (
                <>
                  {props.isItx && (
                    <SectionText
                      dictionary={dictionaryItem(
                        'BasketSummary',
                        'PackagePrice',
                      )}
                    />
                  )}
                  {!props.isItx && (
                    <SectionText
                      dictionary={dictionaryItem('BasketSummary', 'Total')}
                    />
                  )}
                </>
              ) : (
                <SectionText bold>
                  {getDictionaryItem(
                    props.client,
                    'BasketSummary__AmendmentTotal__Text',
                  )}
                  :
                </SectionText>
              )}
              <PriceTotal>
                <b>
                  {formatPrice(
                    isAmendment ? amendBreakdown?.total : props?.totalPrice ?? 0,
                  )}
                </b>
              </PriceTotal>
            </SectionRow>
          </Section>
        </Details>

        <ButtonsContainer isFullBasket={props.isFull}>
          {isAmendment &&
            <AmendmentDiscardButton
              onClick={() => setModal(true)}
              dictionary={dictionaryItem('AvailabilityAmend', 'DiscardChanges')}
            />
          }
          <ButtonCheckout
            disabled={props.disableCheckoutBtn}
            extrasOnly={props.extrasOnly}
            toggleBasket={props.toggleBasket}
            goToTop={false}
            basketScopedOverlappingBookings={
              props.basketScopedOverlappingBookings
            }
            priceDecreaseItemIds={props.priceDecreaseItemIds}
            handleUserAttemptedCheckout={props.handleUserAttemptedCheckout}
            handleUserMissingAddress={props.handleUserMissingAddress}
            basketScopedOverlappingSitesType={
              props.basketScopedOverlappingSitesType
            }
            quote={props.quote}
          />
        </ButtonsContainer>
      </StyledBasketSummary>
      <NoticeModal
        {...modalProps}
        open={isDiscardModalOpen}
        onPrimaryAction={() => handleDiscard()}
        onSecondaryAction={() => setModal(false)}
        onClickOutside={() => setModal(false)}
        onClose={() => setModal(false)}
      />
    </Fragment>
  );
}

BasketSummary.propTypes = {
  booking: PropTypes.shape(IbePropTypes.booking),
  deposit: PropTypes.number,
  disableCheckoutBtn: PropTypes.bool,
  extrasOnly: PropTypes.bool.isRequired,
  handleUserAttemptedCheckout: PropTypes.func,
  handleUserMissingAddress: PropTypes.func,
  isFull: PropTypes.bool,
  isItx: PropTypes.bool.isRequired,
  memberSaving: PropTypes.number,
  openDepositComponentSummaryPopUp: PropTypes.func.isRequired,
  basketScopedOverlappingBookings: PropTypes.bool.isRequired,
  basketScopedOverlappingSitesType: PropTypes.string,
  priceDecreaseItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleBasket: PropTypes.func.isRequired,
  totalPrice: PropTypes.number,
  quote: PropTypes.shape(IbePropTypes.quote),
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  resetSession: PropTypes.func,
};

BasketSummary.defaultProps = {
  booking: null,
  deposit: null,
  disableCheckoutBtn: false,
  totalPrice: null,
  isFull: false,
  handleUserAttemptedCheckout: () => { },
  handleUserMissingAddress: () => { },
  memberSaving: 0,
  basketScopedOverlappingSitesType: BasketScopedOverlappingSitesType.OFF,
  quote: {},
  resetSession: () => { },
};

export default compose(
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(BasketSummary);
