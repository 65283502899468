import gql from 'graphql-tag';

import quoteFragments from './quoteFragments';

export default gql`
${quoteFragments}

mutation AcceptQuoteComponent {
  quoteResponse: acceptQuoteComponent(input: $input)
    @rest(
      type: "QuoteUpdate"
      method: "PATCH",
      path: "Reservations/AcceptQuoteComponents"
    ) {
      quote {
        balanceDueDate
        cancellationPenalty
        cancellationPenaltyAvailabileFrom
        campsiteBookings {
          id
          campsite {
            id
            name
            address {
              country
              county
              addressLines
              postCode
            }
            siteCode
            type
          }
          pitches {
            arrivalDateTime
            id
            code
            type
            bookingDates {
              fromDate
              toDate
            }
            numNights
            errata
            basePrice
            totalPrice
            guests
            partyMemberReference
            supplements
            isNonRefundable
          }
        }
        crossingBookings @type(name: "CrossingBookings") {
        ...CrossingBooking
        }
        deposit
        departureDate
        errorCode
        extras {
          ...Extra
        }
        quoteId
        outfit {
          externalId
          id
          towHeight
          towLength
          towType
          towMake
          towModel
          vehicleHeight
          vehicleLength
          vehicleMake
          vehicleModel
          vehicleReg
          vehicleType
        }
        partyMembers {
          ...PartyMemberFrag
        }
        product
        status
        totalPrice
      }
    }
  }
`;
