import { dictionaryItem } from '../../hocs/withDictionary';
import { isLoggedInMember } from '../isLoggedIn';
import quoteMembershipTypes, {
  getENumWithValue,
} from '../../config/quoteMembershipTypes';
import {
  DIALOG_MEMBERSHIP_STATUS,
  DIALOG_MEMBERSHIP_TYPE,
} from '../../config/dialogMembership';
import { ids } from '../../config/campsiteTypes';
import { RENEWAL_STATUSES } from '../../config/membershipStatus';
import { getProductWithCampsiteType } from '../campsiteTypes';

const getBookableDates = (campsiteType, configuration) => {
  const product = getProductWithCampsiteType(campsiteType, configuration?.products);
  const nonMemberBookableDate
    = product?.nonMemberBookableDate || configuration?.availabilityMaxDate;
  const memberBookableDate = product?.memberBookableDate || configuration?.availabilityMaxDate;
  const memberBookableDateTime = new Date(memberBookableDate).getTime();
  const nonMemberBookableDateTime = new Date(nonMemberBookableDate).getTime();

  return { memberBookableDateTime, nonMemberBookableDateTime };
};

export const getMembershipDialogProps = ({ dialogType, ...props }) => {
  let dialogProps = {
    status: DIALOG_MEMBERSHIP_STATUS.SUCCESS,
    showPrimaryButton: true,
    showSecondaryButton: true,
    showBenefits: true,
    benefits: 'DialogMembership__Benefits__RichText',
    ...props,
  };

  const productTypeMismatchProps = {
    description: 'DialogProductMismatch__Text',
    status: DIALOG_MEMBERSHIP_STATUS.WARNING,
    primaryActionText: dictionaryItem(
      'DialogProductMismatch__Checkout',
    ),
    secondaryActionText: dictionaryItem(
      'DialogProductMismatch__Close',
    ),
    footerActionText: dictionaryItem(
      'DialogProductMismatch__EmptyBasket',
    ),
    showBenefits: false,
  };
  switch (dialogType) {
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_SAVE:
      dialogProps.title = 'DialogSaveByBecomingAMember__Title';
      dialogProps.description = 'DialogSaveByBecomingAMember__Text';
      dialogProps.primaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__AddMembership',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__RemindMeLater',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_AUTO:
      dialogProps.title = 'DialogSaveByBecomingAMember__Automatic__Title';
      dialogProps.description = 'DialogSaveByBecomingAMember__Automatic__Text';
      dialogProps.showSecondaryButton = false;
      dialogProps.showBenefits = false;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__Close',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_OVERSEAS_AUTO:
      dialogProps.title = 'DialogSaveByBecomingAMember__MemberOnlyProduct__Title';
      dialogProps.description = 'DialogSaveByBecomingAMember__Automatic__Text';
      dialogProps.showSecondaryButton = false;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__Close',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REQUIRED:
      dialogProps.title = 'DialogSaveByBecomingAMember__MemberOnly__Title';
      dialogProps.description = 'DialogSaveByBecomingAMember__MemberOnly__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.WARNING;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__AddMembership',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogSaveByBecomingAMember__Decline',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SAVE:
      dialogProps.title = 'DialogRemoveMembership__Title';
      dialogProps.description = 'DialogRemoveMembership__NonMemberSite__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.ERROR;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogRemoveMembership__Decline',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogRemoveMembership__Confirm',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SAVE_SITE:
      dialogProps.title = 'DialogRemoveMembership__Title';
      dialogProps.description =
        'DialogRemoveMembership__RemoveMemberOnlySiteAndNonMemberSite__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.ERROR;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogRemoveMembership__Decline',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogRemoveMembership__Confirm',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SITE:
      dialogProps.title = 'DialogRemoveMembership__Title';
      dialogProps.description =
        'DialogRemoveMembership__RemoveMemberOnlySite__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.ERROR;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogRemoveMembership__Decline',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogRemoveMembership__Confirm',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_OVERSEAS:
      dialogProps.title = 'DialogRemoveMembership__Overseas__Title';
      dialogProps.description = 'DialogRemoveMembership__Overseas__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.ERROR;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogRemoveMembership__Ok',
      );
      break;
    case DIALOG_MEMBERSHIP_TYPE.PRODUCT_TYPE_MISMATCH_UK:
      dialogProps.title = 'DialogProductMismatchUK__Title';
      dialogProps = {
        ...dialogProps,
        ...productTypeMismatchProps,
      };
      break;
    case DIALOG_MEMBERSHIP_TYPE.PRODUCT_TYPE_MISMATCH_OVERSEAS:
      dialogProps.title = 'DialogProductMismatchOverseas__Title';
      dialogProps = {
        ...dialogProps,
        ...productTypeMismatchProps,
      };
      break;
    case DIALOG_MEMBERSHIP_TYPE.PRODUCT_CONFIRM_EMPTY_BASKET:
      dialogProps.title = 'DialogProductEmptyBasket__Title';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.WARNING;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogProductEmptyBasket__Confirm',
      );
      dialogProps.secondaryActionText = dictionaryItem(
        'DialogProductEmptyBasket__Cancel',
      );
      dialogProps.showBenefits = false;
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBER_ADDRESS_MISSING:
      dialogProps.title = 'DialogAddress__Title';
      dialogProps.description = 'DialogAddress__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.WARNING;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogAddress',
      );
      dialogProps.dialogInfoDictionary = 'DialogAddress__Info';
      dialogProps.showBenefits = false;
      dialogProps.showSecondaryButton = false;
      break;
    case DIALOG_MEMBERSHIP_TYPE.MEMBER_ADDRESS_MISSING_PENDING:
      dialogProps.title = 'DialogAddress__Pending__Title';
      dialogProps.description = 'DialogAddress__Pending__Text';
      dialogProps.status = DIALOG_MEMBERSHIP_STATUS.WARNING;
      dialogProps.primaryActionText = dictionaryItem(
        'DialogAddress__Pending',
      );
      dialogProps.showBenefits = false;
      dialogProps.showSecondaryButton = false;
      break;
    default:
      return null;
  }

  return dialogProps;
};

export const getDialogType = (
  quote,
  campsite,
  membershipPricesSelected,
  user,
  payload,
  configuration,
  payloads,
) => {
  const isUserMember = isLoggedInMember(user);
  if (isUserMember || !campsite) return null;

  if (RENEWAL_STATUSES.includes(user?.membershipStatus)) {
    return null;
  }

  const pitchTypeIds = [
    payloads?.touring?.pitchTypeId,
    ...(payloads?.camping ?? []).map(campingPayload => campingPayload.pitchTypeId),
  ];

  const hasMemberExclusivePitch = pitchTypeIds.some(
    (pitchTypeId) => !!campsite.pitches?.find(
      (pitch) => pitch.id === pitchTypeId && pitch.isMemberExclusive,
    ),
  );

  const selectedPitch = campsite.pitches?.find(
    (pitch) => pitch.id === payload?.pitchTypeId,
  );
  const selectedPitchWithDates = payload?.campsiteBooking?.pitches?.find(
    (pitch) => pitch.code === payload?.pitchTypeId,
  );

  const memberOnlySite =
    campsite.type === ids.OVERSEAS_SITE ||
    selectedPitch?.isMemberExclusive || hasMemberExclusivePitch;
  const membershipByCC = getENumWithValue(quoteMembershipTypes.MembershipByCC);
  const membershipByDD = getENumWithValue(quoteMembershipTypes.MembershipByDD);
  const membershipInBasket = [membershipByCC, membershipByDD].includes(
    quote?.membershipType,
  );
  const membershipCost = configuration?.membershipTypes?.directDebit?.costSingle;
  const nonMemberSupplementsCost = quote?.nonMemberSupplementsCost ?? 0;
  const hasRemovedMembershipFromBasket =
    quote?.quoteId && localStorage.quoteId === quote?.quoteId;

  if (!membershipInBasket || !quote?.quoteId) {
    const { memberBookableDateTime, nonMemberBookableDateTime }
      = getBookableDates(campsite.type, configuration);

    if (
      !memberOnlySite
      && selectedPitchWithDates
      && nonMemberBookableDateTime < memberBookableDateTime
      && new Date(selectedPitchWithDates.bookingDates.toDate).getTime() > nonMemberBookableDateTime
    ) {
      return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REQUIRED;
    }
    if (memberOnlySite) {
      return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REQUIRED;
    }
    if (
      (membershipPricesSelected && !memberOnlySite) ||
      (!membershipPricesSelected && !!nonMemberSupplementsCost &&
        (nonMemberSupplementsCost < membershipCost))
    ) {
      return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_SAVE;
    }
    if (
      !membershipPricesSelected &&
      nonMemberSupplementsCost >= membershipCost &&
      !hasRemovedMembershipFromBasket
    ) {
      return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_AUTO;
    }
  }
  return null;
};

export const getRemoveDialogType = (user, quote, configuration) => {
  if (isLoggedInMember(user) || !quote?.quoteId) {
    return null;
  }

  const defaultStatus = {
    hasMemberOnlySite: false,
    hasNonMemberOnlySite: false,
    hasNonMemberOnlySiteInMemberOnlyDateRange: false,
    hasOverseasSite: false,
  };

  const hasCrossingsOrOSNV = quote?.crossingBookings?.length ||
    quote?.extras?.length;

  const {
    hasMemberOnlySite,
    hasNonMemberOnlySite,
    hasNonMemberOnlySiteInMemberOnlyDateRange,
    hasOverseasSite,
  } =
    quote.campsiteBookings?.reduce((status, booking) => {
      const newStatus = { ...status };
      const hasMemberOnlyPitch = booking?.pitches?.some(
        (pitch) => pitch?.isMemberExclusive === true,
      );
      const hasNonMemberOnlyPitch = booking?.pitches?.some(
        (pitch) => pitch?.isMemberExclusive === false,
      );
      const hasOverseasBooking = booking.campsite?.type === ids.OVERSEAS_SITE;
      const hasInvalidNonMemberBookingDateRange = booking?.pitches?.find((pitch) => {
        const { memberBookableDateTime, nonMemberBookableDateTime }
          = getBookableDates(booking.campsite.type, configuration);
        return (
          !pitch.isMemberExclusive
          && nonMemberBookableDateTime < memberBookableDateTime
          && new Date(pitch.bookingDates.toDate).getTime() > nonMemberBookableDateTime
        );
      });
      if (hasMemberOnlyPitch) {
        newStatus.hasMemberOnlySite = true;
      }
      if (hasNonMemberOnlyPitch) {
        newStatus.hasNonMemberOnlySite = true;
      }
      if (hasOverseasBooking) {
        newStatus.hasOverseasSite = true;
      }
      if (hasInvalidNonMemberBookingDateRange) {
        newStatus.hasNonMemberOnlySiteInMemberOnlyDateRange = true;
      }

      return newStatus;
    }, defaultStatus) ?? defaultStatus;

  if (hasOverseasSite || hasCrossingsOrOSNV) {
    return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_OVERSEAS;
  }
  if (hasMemberOnlySite && hasNonMemberOnlySite) {
    return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SAVE_SITE;
  }
  // must execute before check for hasNonMemberOnlySite
  if (hasNonMemberOnlySiteInMemberOnlyDateRange) {
    return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SITE;
  }
  if (hasNonMemberOnlySite) {
    return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SAVE;
  }
  if (hasMemberOnlySite) {
    return DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REMOVE_SITE;
  }
  return null;
};
