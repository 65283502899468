import React from 'react';
import PropTypes from 'prop-types';

import formatPrice from '../../lib/formatPrice';
import { dictionaryItem } from '../../hocs/withDictionary';

import StyledBasketSummary, {
  ButtonPayBalance,
  ButtonAdd,
  Details,
  PriceTotal,
  Section,
  SectionRow,
  SectionRowNav,
  SectionText,
} from './BasketSummary.style';

function BookingBasketSummary({
  amendLoading,
  isOverseas,
  isUpcoming,
  remainingPrice,
  totalPrice,
  onPayBalance,
  onAddSite,
  onAddCrossing,
  onAddOSNV,
}) {
  const hasFooter = isUpcoming && (onPayBalance || onAddSite || onAddCrossing || isOverseas);
  const showAddOSNV = isOverseas && onAddOSNV;
  return (
    <>
      <StyledBasketSummary isBookingBasket>
        <Details>
          <Section>
            {remainingPrice > 0 ? (
              <>
                <SectionRow>
                  <SectionText dictionary={dictionaryItem('BasketSummary', 'TotalBookingCost')} />
                  <SectionText>{formatPrice(totalPrice)}</SectionText>
                </SectionRow>
                <SectionRow>
                  <SectionText weight="bold" dictionary={dictionaryItem('BasketSummary', 'RemainingBalanceToPay')} />
                  <PriceTotal>
                    <b>{formatPrice(remainingPrice)}</b>
                  </PriceTotal>
                </SectionRow>
              </>
            ) : (
              <SectionRow>
                <SectionText weight="bold" dictionary={dictionaryItem('BasketSummary', 'TotalBookingCost')} />
                <PriceTotal>
                  <b>{formatPrice(totalPrice)}</b>
                </PriceTotal>
              </SectionRow>
            )}
          </Section>
        </Details>

        {hasFooter &&
          <Section>
            <SectionRowNav>
              {onPayBalance &&
                <ButtonPayBalance flex={1} onClick={onPayBalance} textTransform="uppercase" size="small" dictionary={dictionaryItem('BasketSummary', 'PayBalance')} />
              }
              {onAddSite &&
                <ButtonAdd flex={1} textTransform="uppercase" size="small" dictionary={dictionaryItem('BasketSummary', 'AddASite')} />
              }
              {onAddCrossing &&
                <ButtonAdd flex={1} textTransform="uppercase" size="small" dictionary={dictionaryItem('BasketSummary', 'AddACrossing')} />
              }
              {showAddOSNV &&
                <ButtonAdd
                  isLoading={amendLoading}
                  onClick={onAddOSNV}
                  flex={2}
                  textTransform="uppercase"
                  size="small"
                  dictionary={dictionaryItem('Amend', 'SiteNightVoucher', 'AddOSNV')}
                />}
            </SectionRowNav>
          </Section>
        }
      </StyledBasketSummary>
    </>
  );
}

BookingBasketSummary.propTypes = {
  amendLoading: PropTypes.bool,
  isOverseas: PropTypes.bool,
  isUpcoming: PropTypes.bool,
  remainingPrice: PropTypes.number,
  totalPrice: PropTypes.number,
  onPayBalance: PropTypes.func,
  onAddSite: PropTypes.func,
  onAddCrossing: PropTypes.func,
  onAddOSNV: PropTypes.func,
};

BookingBasketSummary.defaultProps = {
  amendLoading: false,
  isOverseas: false,
  isUpcoming: false,
  remainingPrice: 0,
  totalPrice: 0,
  onPayBalance: undefined,
  onAddSite: undefined,
  onAddCrossing: undefined,
  onAddOSNV: undefined,
};

export default BookingBasketSummary;
