import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { BadgeBrand } from '../ui/Badge';
import Icon from '../ui/Icon';

import StyledBasketIcon from './BasketIcon.style';

import svgBasket from '../../static/images/icons/Basket.svg';

const propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  count: 0,
  className: '',
  position: 'right',
  size: 'medium',
};

function BasketIcon({
  count,
  className,
  position,
  size,
}) {
  return (
    <StyledBasketIcon className={className} position={position}>
      {['top', 'left'].includes(position) &&
        <BadgeBrand position={position} size={size}>{count}</BadgeBrand>
      }
      <Icon icon={svgBasket} size="1.125rem" />
      {['bottom', 'right'].includes(position) &&
        <BadgeBrand position={position} size={size}>{count}</BadgeBrand>
      }
    </StyledBasketIcon>
  );
}

BasketIcon.propTypes = propTypes;
BasketIcon.defaultProps = defaultProps;

export default memo(BasketIcon);
