import React, { PureComponent } from 'react';

/**
 * Takes mutations and returns loading and error states as props
 * @param {...Object} args Mutation state options
 * @param {string} args.name The mutation function name
 */
const withStateMutation = (...args) => WrappedComponent => class extends PureComponent {
  state = {
    error: null,
    loading: false,
  };

  handleMutation = (options, name) => {
    this.setState({
      loading: true,
      error: null,
    });

    return new Promise((resolve, reject) => this.props[name](options)
      .then((response) => {
        this.setState({
          loading: false,
          error: null,
        });

        resolve(response);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err,
        });

        reject(err);
      }));
  }

  /**
   * Assign mutations to this.handleMutation method
   * @param {Object} acc
   * @param {Object} options
   * @param {string} options.name The function name
   */
  reducer = (acc, { name }) => ({ ...acc, [name]: options => this.handleMutation(options, name) })

  render() {
    const { error, loading } = this.state;

    const props = {
      ...this.props,
      ...args.reduce(this.reducer, {}),
      error,
      loading,
    };

    return <WrappedComponent {...props} />;
  }
};

export default withStateMutation;
