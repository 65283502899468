import styled from 'styled-components';
import { above, below } from '../../styles/libs';

export default styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  ${({ theme }) => below(theme.TABLET)`
    overflow-y: auto;
  `};
`;

export const Wrapper = styled.div`
  width: 50%;
  margin-left: 1rem;
`;

export const SpecialRequestsWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 1rem 1.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ theme }) => above(theme.TABLET)`
    margin-top: auto;
    padding: 1.25rem 1.75rem;
    bottom: 0;
  `}
`;
