import { stringify } from 'query-string';
import moment from 'moment';

import PRODUCT_TYPES from '../../config/products';
import { DATE_FORMAT_DEFAULT } from '../../config/locale';
import { CHILD, ADULT, INFANT } from '../../lib/partyMemberTypes';

export function parseVariables(variables = {}, path = '') {
  const queryString = stringify(variables);
  const pathWithQuery = `${path}?${queryString}`;
  return {
    path: pathWithQuery,
    ...variables,
  };
}

export const buildPartyLabel = (
  label, minAge, maxAge,
) => `${label} (${minAge}${!maxAge ? '+' : ' - '}${maxAge || ''})`;

export const guestOptions = (configuration, isOverseas) => {
  const { products, partyMemberTypes } = configuration;
  const product = products.find(
    (productItem) => productItem.productCode ===
      (isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT),
  );

  const { partyConfig } = product;
  return partyConfig
    .filter((item) => item.fromAge >= 0)
    .map((partyConfigItem, key) => {
      const selectedPartyMemberType = partyMemberTypes.find(
        (partyMemberTypeItem) => partyMemberTypeItem.key === partyConfigItem.type,
      );
      const ageMin = (partyConfig[key + 1]?.toAge ?? 0) + (partyConfigItem.type === INFANT ? 0 : 1);
      const ageMax = key === 0 ? 0 : partyConfig[key].toAge ?? 0;

      return {
        id: partyConfigItem.type,
        type: partyConfigItem.type,
        label: buildPartyLabel(selectedPartyMemberType?.value, ageMin, ageMax),
        ageMin,
        ageMax,
      };
    });
};

export const getAge = (dateOfBirth, arrivalDate) => {
  const now = arrivalDate ? moment(arrivalDate) : moment(new Date());
  const dob = moment(dateOfBirth);
  now.set({
    hour: 1, minute: 0, second: 0, millisecond: 0,
  });
  dob.set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  });
  const isBirthdayOnArrival = now.date() === dob.date() && now.month() === dob.month();

  const roundFunc = isBirthdayOnArrival ? Math.round : Math.floor;
  return roundFunc(now.diff(dob, 'years'));
};

export const isDateValid = (date) => moment(date, DATE_FORMAT_DEFAULT, true).isValid();

export const getTypeByDateOfBirth = (dateOfBirth, arrivalTime, partyOptions, pitchConfig) => {
  const age = getAge(dateOfBirth, arrivalTime);
  const childConfig = partyOptions.find(option => option.type === CHILD);
  if (age > (pitchConfig.maxChildAge ?? childConfig?.ageMax)) {
    return ADULT;
  }
  if (age >= (pitchConfig.minChildAge ?? childConfig.ageMin)) {
    return CHILD;
  }
  return INFANT;
};

export const validateDateOfBirth = (dateOfBirth, type, partyOptions, arrivalDate, pitchConfig) => {
  const partyOption = partyOptions.find(option => option.type === Number(type));
  const childOption = partyOptions.find(option => option.type === CHILD);
  if (type === ADULT) {
    return { isValidAge: true };
  }
  if (!partyOption) {
    return { guestType: type, isValidAge: false };
  }

  const age = getAge(dateOfBirth, arrivalDate);

  const isChildOrInfantType = type === CHILD || type === INFANT;

  let minAge = partyOption.ageMin;
  let maxAge = partyOption.ageMax;

  if (type === ADULT) {
    minAge = pitchConfig?.adultFromAge ?? minAge;
  }

  if (type === CHILD) {
    minAge = pitchConfig?.childFromAge ?? minAge;
    maxAge = pitchConfig?.childToAge ?? maxAge;
  }

  if (type === INFANT) {
    minAge = partyOption.ageMin ?? minAge;
    maxAge = pitchConfig?.minChildAge ? (pitchConfig?.minChildAge - 1) : maxAge;
  }

  const isValidAge = isChildOrInfantType ?
    (age >= minAge && age <= maxAge) : age >= minAge;

  let guestType = type;
  if (!isValidAge) {
    if (age < (pitchConfig?.childFromAge ?? childOption.ageMin)) {
      guestType = INFANT;
    } else if (age > (pitchConfig?.childToAge ?? childOption.ageMax)) {
      guestType = ADULT;
    } else {
      guestType = CHILD;
    }
  }
  return { guestType, isValidAge, age };
};
