import styled from 'styled-components';

import {
  Header as BasketItemHeader,
  MiniBasketItem as BasketItemMiniBasketItem,
} from './BasketItem.style';
import { ButtonLinkProminent as Button } from '../ui/Button';
import { below } from '../../styles/libs';

export { Title } from './BasketItem.style';

export const MiniBasketItem = styled(BasketItemMiniBasketItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
`;

export const Header = styled(BasketItemHeader)`
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0.75rem 0;
  justify-content: space-between;
`;

export const AmendPartyNav = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: flex-end;
  margin: 0.75rem 0;
  ${({ theme }) => below(theme.DESKTOP)`
    flex-direction: column;
  `}
`;

export const ButtonLinkProminent = styled(Button)`
  margin-left: 1rem;
  line-height: 1.5rem;
  ${({ theme }) => below(theme.DESKTOP)`
    margin-left: 0;
  `}
`;
