import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { withTheme } from 'styled-components';

import { standardDefaultProps, standardPropTypes } from '../../lib/standardProptypes';

import { DrawerHandle } from '../ui/Drawer';
import BasketContent from './BasketContent';
import Icon from '../ui/Icon';

import StyledBasket, {
  BasketHandle,
  BasketHandleIcon,
  BasketWrapper,
  DrawerHandleBody,
  DrawerContent,
} from './Basket.style';

import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';

import { defaults as defaultQuote } from '../../resolvers/quote';

import GET_BOOKING_DETAILS from '../ManageMyBookings/graphql/getCurrentUserBooking.gql';
import GET_QUOTE from '../Quote/graphql/getQuote';
import IbePropTypes from '../../IbePropTypes';
import { isMembershipInBasket } from '../../config/quoteMembershipTypes';
import ViewManager from '../../managers/view';
import theme from '../../styles/config/theme';
import FetchPolicy from '../../constants/FetchPolicy';
import zIndex from '../../config/zIndex';
import { getBookingFromData } from '../ManageMyBookings/helpers';
import { quoteUpdatedOsnv } from '../../lib/helpers/quote';
import { populateQuoteFromBooking } from '../../lib/helpers/amend';

const STICKY_MODE = {
  none: 'None',
  sticky: 'Sticky',
  bottom: 'Bottom',
};

export function basketCount(quote) {
  if (!quote) {
    return 0;
  }

  let countPitches = 0;
  let countExtras = 0;
  let countCrossings = 0;

  if (quote.campsiteBookings) {
    countPitches = quote.campsiteBookings.reduce((acc, cur) => (acc + cur.pitches.length), 0);
  }
  countCrossings = quote.crossingBookings.length;
  // TODO: IF some of the extras are not supposed to be displayed in basket, modify this!.
  countExtras = quote.extras.length;

  const countMembership = isMembershipInBasket(quote) ? 1 : 0;

  return countPitches + countExtras + countCrossings + countMembership;
}

class Basket extends PureComponent {
  static propTypes = {
    booking: PropTypes.shape(IbePropTypes.booking),
    isVisible: PropTypes.bool.isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    currentQuote: PropTypes.shape(IbePropTypes.quote),
    refetch: PropTypes.func,
    toggleBasket: PropTypes.func.isRequired,
    theme: PropTypes.shape({}),
    query: PropTypes.shape(IbePropTypes.query),
    isExpanded: PropTypes.bool,
    ...standardPropTypes,
  }

  static defaultProps = {
    ...defaultQuote,
    ...standardDefaultProps,
    refetch() { },
    theme: {},
    query: {},
    booking: undefined,
    currentQuote: undefined,
    isExpanded: false,
  }

  state = {
    full: false,
    stickyMode: STICKY_MODE.none,
    basketOpenedOnLoad: false,
  }

  basketWrapper = React.createRef();

  drawerRef = React.createRef();

  componentDidMount() {
    ViewManager.invalidate();
    ViewManager.on(ViewManager.EVENT_UPDATE, this.onUpdate);
  }

  componentDidUpdate(prevProps) {
    const { isVisible, isExpanded } = this.props;
    this.handleOsnvUpdate();
    if (!prevProps.isVisible && isVisible) {
      const { top } = this.getBasketBounds();

      window.scrollBy({
        top,
        behavior: 'smooth',
      });
    }
    if (prevProps.isExpanded !== isExpanded) {
      this.handleFullClick(undefined, true);
    }
  }

  componentWillUnmount() {
    ViewManager.off(ViewManager.EVENT_UPDATE, this.onUpdate);
  }

  handleOsnvUpdate = () => {
    const { currentQuote, booking, toggleBasket } = this.props;
    if (quoteUpdatedOsnv(currentQuote, booking) && !this.state.basketOpenedOnLoad) {
      toggleBasket(true);
      this.setState({
        basketOpenedOnLoad: true,
      }, () => {
        toggleBasket(true);
      });
    }
  }

  onUpdate = ({ scrollUpdate, resizeUpdate }) => {
    // JS handling of sticky to cover non height of footer
    if (scrollUpdate || resizeUpdate) {
      const { scrolledPassedBottom, scrolledPassedTop } = this.getBasketBounds();

      let stickyMode = STICKY_MODE.none;

      if (ViewManager.isAbove(theme.MOBILE_LARGE)) {
        if (scrolledPassedTop && !scrolledPassedBottom) {
          stickyMode = STICKY_MODE.sticky;
        }

        if (scrolledPassedBottom) {
          stickyMode = STICKY_MODE.bottom;
        }
      }

      let hasTransitionedToHandheld = false;
      if (resizeUpdate && ViewManager.hasTransitionedBelow(theme.TABLET)) {
        hasTransitionedToHandheld = true;
      }

      if (
        stickyMode !== this.state.stickyMode
        || (stickyMode && resizeUpdate)
        || hasTransitionedToHandheld
      ) {
        // // directly set for performance, use of state is too slow
        if (this.drawerRef.current) {
          if (STICKY_MODE.sticky === stickyMode) {
            this.drawerRef.current.style.position = 'fixed';
          } else {
            this.drawerRef.current.style.position = '';
          }

          if (STICKY_MODE.bottom === stickyMode) {
            this.drawerRef.current.style.top = 'auto';
            this.drawerRef.current.style.bottom = '0';
          } else {
            this.drawerRef.current.style.top = '';
            this.drawerRef.current.style.bottom = '';
          }

          this.setState({ stickyMode });
        }
      }
    }
  };

  getBasketBounds = () => {
    if (!this.basketWrapper.current) return {};

    const {
      bottom,
      left,
      right,
      top,
    } = this.basketWrapper.current.getBoundingClientRect();

    const scrolledPassedTop = top <= 0;
    const scrolledPassedBottom = bottom <= (
      window.innerHeight || window.document.documentElement.clientHeight
    );

    return {
      bottom,
      left,
      right,
      scrolledPassedBottom,
      scrolledPassedTop,
      top,
    };
  }

  /**
   * @summary Toggle full basket view
   */
  handleFullClick = (e, fullOverride) => {
    const full = fullOverride ?? !this.state.full;
    this.setState({ full });
  }

  render() {
    const {
      booking,
      error,
      loading,
      overlay,
      quote: currentQuote,
      toggleBasket,
      isMobile,
      isVisible,
      query,
    } = this.props;

    const isBookingWidget = query.bookingWidget === 'true';
    if (loading || isBookingWidget) return null;
    const quote = populateQuoteFromBooking(currentQuote, booking);
    const count = basketCount(quote);

    return (
      <>
        <BasketWrapper
          data-basket-wrapper
          isMobile={isMobile}
          ref={this.basketWrapper}
          isVisible={isVisible}
          zIndex={isVisible ? zIndex.BASKET_WRAPPER_OPEN : zIndex.BASKET_WRAPPER}
        >
          <StyledBasket
            direction="right"
            full={this.state.full}
            offset={theme.BASKET_TOGGLE_WIDTH}
            onRequestChange={toggleBasket}
            open={isVisible}
            overlay={overlay}
            zIndex={3000}
            forwardRef={this.drawerRef}
          >
            <DrawerHandle handleLabel="Open basket">
              <DrawerHandleBody>
                <BasketHandle>
                  <BasketHandleIcon
                    count={count}
                    position="top"
                    size="large"
                  />
                  <Icon icon={isVisible ? svgArrowRight : svgArrowLeft} />
                </BasketHandle>
              </DrawerHandleBody>
            </DrawerHandle>
            <DrawerContent>
              <BasketContent
                booking={booking}
                count={count}
                quoteError={error}
                isFull={this.state.full}
                handleFullClick={this.handleFullClick}
                isVisible={isVisible}
                quoteLoading={loading}
                quote={quote || defaultQuote}
                activeQuote={quote || defaultQuote}
                query={query}
                refetchQuote={this.props.refetch}
                toggleBasket={toggleBasket}
              />
            </DrawerContent>
          </StyledBasket>
        </BasketWrapper>
      </>
    );
  }
}

export default compose(
  withTheme,
  graphql(GET_QUOTE, {
    options: {
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    },
    skip: ({ query }) => query?.bookingWidget === 'true',
    props: ({ data }) => ({ ...data }),
  }),
  graphql(GET_BOOKING_DETAILS, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        bookingReference: props.quote?.bookingReference,
        skip: !props.quote?.bookingReference,
      },
    }),
    props: (props) => ({
      booking: getBookingFromData(props.data),
    }),
  }),
)(Basket);
