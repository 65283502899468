import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import BasketIcon from './BasketIcon';
import { basketCount } from './Basket';
import StyledBasketToggle from './BasketToggle.style';

import GET_QUOTE from '../Quote/graphql/getQuote';

const propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
};

const BasketToggle = ({ className, handleClick }) => (
  <Query
    fetchPolicy="cache-and-network"
    query={GET_QUOTE}
  >
    {({ data: { quote } }) => (
      <StyledBasketToggle className={className} onClick={() => handleClick()}>
        <BasketIcon
          count={basketCount(quote)}
        />
      </StyledBasketToggle>
    )}
  </Query>
);

BasketToggle.propTypes = propTypes;
BasketToggle.defaultProps = defaultProps;

export default BasketToggle;
