export const DIALOG_MEMBERSHIP_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const DIALOG_MEMBERSHIP_TYPE = {
  MEMBERSHIP_SAVE: 'membershipSave',
  MEMBERSHIP_AUTO: 'membershipAuto',
  MEMBERSHIP_REQUIRED: 'membershipRequired',
  MEMBERSHIP_OVERSEAS_AUTO: 'membershipOverseasAuto',
  MEMBERSHIP_REMOVE_SAVE: 'membershipRemoveSave',
  MEMBERSHIP_REMOVE_SAVE_SITE: 'membershipRemoveSaveSite',
  MEMBERSHIP_REMOVE_SITE: 'membershipRemoveSite',
  MEMBERSHIP_REMOVE_OVERSEAS: 'membershipRemoveOverseas',
  MEMBERSHIP_RENEWAL: 'membershipRenewal',
  MEMBER_ADDRESS_MISSING: 'memberAddressMissing',
  MEMBER_ADDRESS_MISSING_PENDING: 'memberAddressMissingPending',
  PRODUCT_TYPE_MISMATCH_UK: 'productTypeMismatchUK',
  PRODUCT_TYPE_MISMATCH_OVERSEAS: 'productTypeMismatchOverseas',
};
