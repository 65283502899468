/* eslint-disable indent */
import styled, { css } from 'styled-components';

import { above, below } from '../../../styles/libs';

const translation = {
  top: 'translateY',
  right: 'translateX',
  bottom: 'translateY',
  left: 'translateX',
};

const plusMinus = {
  top: '+',
  right: '+',
  bottom: '-',
  left: '-',
};

function translate(direction, distance) {
  const expression = distance ? `(${plusMinus[direction]}${distance})` : '';
  return `${translation[direction]}${expression}`;
}

export default styled.div`
  ${({
    direction,
    theme,
    open,
    orientation,
    position,
    width,
  }) => css`
    display: flex;
    flex-direction: ${(orientation === 'vertical' ? 'row' : 'column')};
    flex-wrap: wrap;
    position: ${position};

    /* props used for css property instead of value e.g top: 0 or bottom: 0 */
    ${direction}: 0;

    min-height:
      ${() => {
        const size = `${width}px` || 'auto';
        return orientation === 'vertical' ? '100vh' : size;
      }};

    ${() => below(theme.TABLET)`
      min-height: 100%;
    `}
  `}


  ${({
    direction,
    offset,
    open,
    position,
    theme,
    width,
    useMarginForAnimation,
  }) => {
    if (open) {
      return css`
        ${useMarginForAnimation
          ? `margin-${direction}: 0;`
          : `transform: ${translate(direction, '0%')};`
        }
      `;
    }

    const size = `${width}px` || 'auto';

    // Calculate transform with a custom offset
    if (offset && size !== 'auto') {
      return css`
        ${below(theme.TABLET)`
          transform: ${translate(direction, size)};
        `}

        ${above(theme.TABLET)`
          ${useMarginForAnimation
            ? `margin-${direction}: calc(-${size} + ${offset});`
            : `transform: ${translate(direction)}(${plusMinus[direction]}100%) ${translate(direction)}(${plusMinus[direction] === '+' ? '-' : '+'}${offset});`
          }
        `}
      `;
    }

    return css`
      ${useMarginForAnimation
        ? `margin-${direction}: -${width}px;`
        : `transform: ${translate(direction, `${width}px`)};`
      }
    `;
  }}

  ${({ animate, useMarginForAnimation }) => {
    if (animate) {
      return css`
        transition: ${useMarginForAnimation
          ? 'margin 250ms ease' : 'transform 250ms ease'}, width 250ms ease;
      `;
    }

    return css``;
  }}

  width: 100vw;
  backface-visibility: hidden;
  will-change: ${props => (props.useMarginForAnimation
    ? 'margin' : 'transform')};
  z-index: ${({ zIndex }) => zIndex};

  ${({
    maxWidth,
    orientation,
    position,
    theme,
    width,
  }) => above(theme.TABLET)`
    position: ${position};
    width: ${() => {
      const size = `${width}px` || 'auto';
      return orientation === 'horizontal' ? '100vw' : size;
    }};
  `};
`;

export const Container = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  background-color: ${props => props.backgroundColor || props.theme.COLOR_WHITE};
  z-index: 2;
  ${({ theme }) => below(theme.TABLET)`
    position: static;
  `};
`;
