import React from 'react';
import PropTypes from 'prop-types';

import Title from '../ui/Title';
import Button, { ButtonIcon } from '../ui/Button';

import { dictionaryItem } from '../../hocs/withDictionary';
import {
  Actions,
  Close,
  Content,
  Header,
  Outer,
  Quote,
} from './BasketHeader.style';
import BasketHoldInventoryManager from './BasketHoldInventoryManager';

import svgClose from '../../static/images/icons/Close.svg';
import svgFull from '../../static/images/icons/Full.svg';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleFullClick: PropTypes.func.isRequired,
  isAmend: PropTypes.bool,
  isFull: PropTypes.bool.isRequired,
  quoteId: PropTypes.string,
  onCloseHoldInventoryPopup: PropTypes.func,
};

const defaultProps = {
  isAmend: false,
  quoteId: null,
  onCloseHoldInventoryPopup: () => {},
};

function BasketHeader(props) {
  return (
    <Outer>
      <BasketHoldInventoryManager
        quoteId={props.quoteId}
        onClosePopup={props.onCloseHoldInventoryPopup}
      />
      <Content>
        <Close
          crossWidth="0.875rem"
          onClick={() => props.handleClose(false)}
          width="1.5rem"
          height="1.5rem"
        />
        <Header>
          <Title dictionary={dictionaryItem('BasketHeader')} size={1} />
          {props.quoteId && !props.isAmend &&
            <Quote>Basket Quote: {props.quoteId}</Quote>
          }
        </Header>
        <Actions>
          <Button
            ariaLabel="Show full basket"
            onClick={props.handleFullClick}
          >
            <ButtonIcon icon={props.isFull ? svgClose : svgFull} />
            <span>{props.isFull ? 'Close' : 'Show'} Full Basket</span>
          </Button>
        </Actions>
      </Content>
    </Outer>
  );
}

BasketHeader.propTypes = propTypes;
BasketHeader.defaultProps = defaultProps;

export default BasketHeader;
